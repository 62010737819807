import React, { useState, useEffect } from 'react'
import FramePeople from "../../Assets/Image/Frame.png"
import textName from "../../Assets/Image/text.png"
import textBlack from "../../Assets/Image/text black.png"
import tree from "../../Assets/Image/tree.png"
import Back from "../../Assets/Image/Backs.png"
import { useLocation, useNavigate } from 'react-router-dom'
import OTPInput from 'react-otp-input'
import { verificationEmail } from '../../Services/ApiServices'
import toast from 'react-hot-toast'
import { ThreeCircles } from 'react-loader-spinner'

function Verification() {

    const navigate = useNavigate()
    const location = useLocation()
    const tokens = localStorage.getItem("UToken")
    
    const [otp, setOtp] = useState('')
    const [isLoading, setIsLoading] = useState(false)


    useEffect(() => {
        if (tokens) {
            navigate("/dashboard")
        }
    }, [])

    let mail;
    try {
        const { Email } = location.state;
        mail = Email
    } catch (error) {
        console.log("err");
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setIsLoading(true)
        let param = {
            email_id: mail,
            email_otp: otp
        }
        verificationEmail(param)
            .then((res) => {
                console.log("res >>", res);
                const status = res.data.Status;
                if (status == 1) {
                    console.log("verification >>", res.data);
                    navigate("/resetpassword", { state: { Email: mail } })
                } else {
                    toast.error(res.data.Message)
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("err >>", err);
                setIsLoading(false)
            })
    }

    return (
        <div className="grid grid-cols-12 gap-x-6 bg-white">
            <div className='col-span-12 intro-y md:col-span-6 bg-black h-[100vh] md:flex items-end justify-center hidden'>
                <div>
                    <div className='flex justify-center pb-52'>
                        <img src={textName} className='w-60' alt="" />
                    </div>
                    <div className='flex h-[400px] md:h-[500px] 2xl:col-span-6 ms-10 relative'>
                        <img src={FramePeople} className='' alt="" />
                        <img src={tree} className='xl:block hidden' alt="" />
                    </div>
                </div>
            </div>
            <div className='col-span-12 intro-y md:col-span-6 '>
                <div>
                    <div className='py-6 px-6 md:px-0'>
                        <img src={Back} width={55} onClick={() => { navigate("/forgotpassword") }} alt="" />
                    </div>
                    <div className='text-center flex md:items-center justify-center items-top h-[80vh]'>
                        <form onSubmit={handleSubmit} className='md:mt-0 mt-10'>
                            <div className='flex justify-center mb-20 md:hidden block'>
                                <img src={textBlack} className='w-52' alt="" />
                            </div>
                            <p className='fontNew text-5xl font-bold p-3'>OTP</p>
                            <p className='fontNew text-lg text-slate-500'>Enter 6 digit verification code</p>
                            <div className='my-7'>
                                <OTPInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={4}
                                    inputType="tel"
                                    placeholder='0000'
                                    renderInput={(props) => <input {...props} className="otp fontNew bg-zinc-100 outline-none rounded-2xl border-2 border-slate-300 m-1 md:m-2 text-center text-3xl font-medium" />}
                                />
                            </div>
                            <div>
                                <button type='submit' className='fontNew py-5 w-full rounded-2xl text-white font-bold text-base w-72' style={{ backgroundColor: "#7424F2" }}>
                                    {
                                        isLoading ?
                                            <div className='flex justify-center'>
                                                <ThreeCircles
                                                    width={23}
                                                    height={23}
                                                    color="#fff"
                                                    ariaLabel="circles-loading"
                                                    wrapperStyle={{}}
                                                    visible={true}
                                                    wrapperClass=""
                                                />
                                            </div>
                                            :
                                            "Confirm"
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Verification