import React, { useEffect, useState } from 'react'
import FramePeople from "../../Assets/Image/Frame.png"
import textName from "../../Assets/Image/text.png"
import textBlack from "../../Assets/Image/text black.png"
import tree from "../../Assets/Image/tree.png"
import Email from "../../Assets/svg/sms.svg"
import Key from "../../Assets/svg/key.svg"
import Eye from "../../Assets/svg/eye.svg"
import EyeSlash from "../../Assets/svg/eye-slash.svg"
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import { ADMIN_LOGIN_API } from '../../Services/Api'
import toast from 'react-hot-toast'
import { ThreeCircles } from 'react-loader-spinner'
import { peopleImages, trees } from '../../Assets/Base64/Base64'

function Login() {

    const navigate = useNavigate()

    const [eyes, setEyes] = useState(0)
    const [texts, setTexts] = useState("password")
    const [formData, setFormData] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    const tokens = localStorage.getItem("UToken")

    useEffect(() => {
        if (tokens) {
            navigate("/dashboard")
        }
    }, [])


    const handleEyes = () => {
        const flag = eyes == 0 ? 1 : 0
        setEyes(flag)

        const text = texts == "password" ? "text" : "password"
        setTexts(text)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setIsLoading(true)
        axios.post(ADMIN_LOGIN_API, {
            email_id: formData.email_id,
            password: formData.password,
            device_type: "web",
            device_id: "123",
            device_token: "abc",
        }, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((res) => {
                console.log("res >>", res);
                const token = res.data.UserToken;
                const userInfo = res.data.info;
                const status = res?.data?.Status;
                if (status == 1) {
                    toast.success(res.data.Message)
                    localStorage.setItem("UToken", token)
                    localStorage.setItem('Admin', JSON.stringify(userInfo));
                    navigate("/dashboard")
                } else {
                    toast.error(res.data.Message)
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("err >>", err);
                setIsLoading(false)
            })
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }

    return (
        <div className="grid grid-cols-12 gap-x-6 bg-white">
            <div className='col-span-12 intro-y md:col-span-6 bg-black h-[100vh] md:flex items-end justify-center hidden'>
                <div>
                    <div className='flex justify-center pb-52'>
                        <img src={textName} className='w-60' alt="" />
                    </div>
                    <div className='flex h-[400px] md:h-[500px] 2xl:col-span-6 ms-10 relative'>
                        <img src={peopleImages} className='' alt="" />
                        <img src={trees} className='xl:block hidden' alt="" />
                    </div>
                </div>
            </div>
            <div className='col-span-12 intro-y md:col-span-6 '>
                <div className='text-center flex justify-center items-center h-[100vh]'>
                    <div>
                        <div className='flex justify-center mb-20 md:hidden block'>
                            <img src={textBlack} className='w-52' alt="" />
                        </div>
                        <p className='fontNew text-5xl font-bold p-3'>Welcome!</p>
                        <p className='fontNew text-lg text-slate-500'>Please log in to your account to continue</p>
                        <form onSubmit={handleSubmit}>
                            <div className='mt-10 mb-6'>
                                <div className='bg-zinc-100 flex p-2 rounded-2xl border-2 border-slate-300 my-3'>
                                    <img src={Email} className='p-2 w-11' alt="" />
                                    <input
                                        required
                                        type="email"
                                        className='bg-transparent border-none outline-none w-full text-base fontNew'
                                        placeholder='Email Address'
                                        name='email_id'
                                        autoComplete='off'
                                        onChange={(e) => handleChange(e)}
                                    />
                                </div>
                                <div className='bg-zinc-100 flex p-2 rounded-2xl border-2 border-slate-300 my-3'>
                                    <img src={Key} className='p-2 w-11' alt="" />
                                    <input
                                        type={texts}
                                        required
                                        className='bg-transparent border-none outline-none w-full text-base fontNew'
                                        placeholder='Password'
                                        name='password'
                                        onChange={(e) => handleChange(e)}
                                    />
                                    {
                                        eyes == 0 ?
                                            <img src={EyeSlash} onClick={handleEyes} className='p-2 w-10 cursor-pointer' alt="" />
                                            :
                                            <img src={Eye} onClick={handleEyes} className='p-2 w-10 cursor-pointer' alt="" />
                                    }
                                </div>
                            </div>
                            <div>
                                <button type='submit' className='fontNew py-5 w-full rounded-2xl flex justify-center text-white font-bold text-base bg-[#7424F2]'>
                                    {
                                        isLoading ?
                                            <ThreeCircles
                                                width={23}
                                                height={23}
                                                color="#fff"
                                                ariaLabel="circles-loading"
                                                wrapperStyle={{}}
                                                visible={true}
                                                wrapperClass=""
                                            />
                                            :
                                            "Log in Now"
                                    }
                                </button>
                            </div>
                        </form>
                        <p className='fontNew text-lg font-semibold mt-9 cursor-pointer' onClick={() => { navigate("/forgotpassword") }}>Forgot Password?</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login