import React, { useEffect, useState } from 'react'
import card from '../../Assets/Image/purplecard.png'
import email from '../../Assets/Image/email 1.png'
import FormSelect from '../../Base-Component/FormSelect/FormSelect'
import grammerly from '../../Assets/Image/grammerly.png'
import facebook from '../../Assets/svg/facebook.svg'
import instagram from '../../Assets/svg/whatsapp.svg'
import snapchat from '../../Assets/svg/snapchat.svg'
import xbtn from '../../Assets/svg/x button.svg'
import Dialog from '../../Base-Component/Dialog/Dialog'
import axios from 'axios'
import { DASHBOARD_COUNT_API, IMG_URL } from '../../Services/Api'
import { getClockList, getShiftList } from '../../Services/ApiServices'
import leftbtn from '../../Assets/Image/left btn.png'
import rightbtn from '../../Assets/Image/right btn.png'
import { Bars } from 'react-loader-spinner'
import moment from 'moment';
import Zoom from 'react-medium-image-zoom'
import noImage from "../../Assets/Image/noImage.png";
import { useNavigate } from 'react-router-dom'

function Dashboard() {

    const navigate = useNavigate()
    const tokens = localStorage.getItem("UToken")

    const [isModalOpens, setModalOpens] = useState(false);
    const [isLoader, setIsLoader] = useState(false)
    const [isLoding, setIsLoding] = useState(false)
    const [copy, setCopy] = useState(false)

    const [isCount, setIsCount] = useState({})
    const [selectShift, setSelectShift] = useState(0)
    const [pageNo, setPageNo] = useState(1)
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState()
    const [totalCount, setTotalCount] = useState()


    const [linkData, setLinkData] = useState("https://www.inviteusers.com")

    const [userData, setUserData] = useState([])

    const [notificationData, setNotificationData] = useState([])


    useEffect(() => {
        handleDashboardCount()
        if (!tokens) {
            navigate("/login")
        }
    }, [])

    useEffect(() => {
        handleShift()
    }, [pageNo, selectShift])

    useEffect(() => {
        handleClock()
    }, [page])

    const handleDashboardCount = () => {
        axios.post(DASHBOARD_COUNT_API)
            .then((res) => {
                const status = res.data.Status;
                const data = res.data.info;
                if (status == 1) {
                    setIsCount(data)
                } else {
                    console.log("error", res.data.Message);
                }
            })
            .catch((err) => {
                console.log("err", err);
            })
    }

    const handleShift = () => {
        setIsLoader(true)
        let param = {
            page_no: pageNo,
            shift_status: selectShift
        }
        getShiftList(param)
            .then((res) => {
                const status = res.data.Status;
                const data = res.data.info;
                if (status == 1) {
                    setUserData(data)
                    setTotalPage(res.data.total_page)
                } else {
                    console.log("error >>", res.data.Message);
                }
                setIsLoader(false)
            })
            .catch((err) => {
                console.log("err >>", err);
                setIsLoader(false)
            })
    }

    const handleClock = () => {
        setIsLoding(true)
        let param = {
            page_no: page,
        }
        getClockList(param)
            .then((res) => {
                const status = res.data.Status;
                const data = res.data.info;
                if (status == 1) {
                    setNotificationData(data)
                    setTotalCount(res.data.total_page)
                } else {
                    console.log("error >>", res.data.Message);
                }
                setIsLoding(false)
            })
            .catch((err) => {
                console.log("err >>", err);
                setIsLoding(false)
            })
    }

    const renderPaginationButtonss = () => {
        const maxPagesToShow = 3;
        const buttons = [];
        const startPage = Math.max(1, pageNo - Math.floor(maxPagesToShow / 2));
        const endPage = Math.min(totalPage, startPage + maxPagesToShow - 1);

        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <button
                    key={i}
                    onClick={() => handlePageChange(i)}
                    className={i === pageNo ? 'px-3 py-1 mx-1 bg-black rounded-full text-white' : 'px-3 py-1 mx-1'}
                >
                    {i}
                </button>
            );
        }
        return buttons;
    };

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const renderPaginationButton = () => {
        const maxPagesToShow = 3;
        const buttons = [];
        const startPage = Math.max(1, page - Math.floor(maxPagesToShow / 2));
        const endPage = Math.min(totalCount, startPage + maxPagesToShow - 1);

        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <button
                    key={i}
                    onClick={() => handlePageChanges(i)}
                    className={i === page ? 'px-3 py-1 mx-1 bg-black rounded-full text-white' : 'px-3 py-1 mx-1'}
                >
                    {i}
                </button>
            );
        }
        return buttons;
    };

    const handlePageChanges = (page) => {
        setPage(page);
    };

    const handleCopy = (e) => {
        setCopy(true)
        navigator.clipboard.writeText(e);
    }

    return (
        <div className='grid grid-cols-12 gap-2'>
            <div className='col-span-12 lg:col-span-12 2xl:col-span-8'>
                <div className='my-8'>
                    <div className='text-2xl font-semibold fontNew mx-2 my-3'>
                        <p>Dashboard</p>
                    </div>
                    <div className='flex flex-wrap'>
                        <div className='md:w-60 w-full bg-white p-5 rounded-2xl fontNew hover:drop-shadow-lg duration-300 m-2 cursor-pointer'>
                            <p className='text-4xl pb-3 font-semibold py-2'>{isCount.Total_Ongoing_Shifts || "0"}</p>
                            <div className='flex items-center justify-between'>
                                <p className='font-medium text-lg'>Ongoing Shifts</p>
                                {/* <div className='flex items-center bg-rose-100 px-2 py-1 rounded-md'>
                                    <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.5 1.75V9.75" stroke="#FF5E84" strokeWidth="2" strokeLinecap="round" />
                                        <path d="M1 7.25L4.5 11.25L8 7.25" stroke="#FF5E84" strokeWidth="2" strokeLinecap="round" />
                                    </svg>
                                    <p className='ps-1 font-semibold text-rose-400'>0</p>
                                </div> */}
                            </div>
                        </div>

                        <div className='md:w-60 w-full bg-white p-5 rounded-2xl fontNew hover:drop-shadow-lg duration-300 m-2 cursor-pointer'>
                            <p className='text-4xl pb-3 font-semibold py-2'>{isCount.Total_Shifts || "0"}</p>
                            <div className='flex items-center justify-between'>
                                <p className='font-medium text-lg'>Total Shifts</p>
                                {/* <div className='flex items-center bg-[#038a0033] px-2 py-1 rounded-md'>
                                    <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.5 11.25V3.25" stroke="#038A00" strokeWidth="2" strokeLinecap="round" />
                                        <path d="M1 5.75L4.5 1.75L8 5.75" stroke="#038A00" strokeWidth="2" strokeLinecap="round" />
                                    </svg>
                                    <p className='ps-1 font-semibold text-green-600'>0</p>
                                </div> */}
                            </div>
                        </div>

                        <div className='md:w-60 w-full bg-white p-5 rounded-2xl fontNew hover:drop-shadow-lg duration-300 m-2 cursor-pointer' onClick={() => { navigate("/earnings") }} >
                            <p className='text-4xl pb-3 font-semibold py-2'>${isCount.Total_Earning || "0"}</p>
                            <div className='flex items-center justify-start'>
                                <p className='font-medium text-lg'>Earnings</p>
                            </div>
                        </div>

                        <div className='md:w-60 w-full bg-white p-5 rounded-2xl fontNew hover:drop-shadow-lg duration-300 m-2 cursor-pointer' onClick={() => { navigate("/professional") }} >
                            <p className='text-4xl pb-3 font-semibold py-2'>{isCount.Total_Professional || "0"}</p>
                            <div className='flex items-center justify-between'>
                                <p className='font-medium text-lg'>Professionals</p>
                                {/* <div className='flex items-center bg-[#038a0033] px-2 py-1 rounded-md'>
                                    <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.5 11.25V3.25" stroke="#038A00" strokeWidth="2" strokeLinecap="round" />
                                        <path d="M1 5.75L4.5 1.75L8 5.75" stroke="#038A00" strokeWidth="2" strokeLinecap="round" />
                                    </svg>
                                    <p className='ps-1 font-semibold text-green-600'>0</p>
                                </div> */}
                            </div>
                        </div>

                        <div className='md:w-60 w-full bg-white p-5 rounded-2xl fontNew hover:drop-shadow-lg duration-300 m-2 cursor-pointer' onClick={() => { navigate("/users") }} >
                            <p className='text-4xl pb-3 font-semibold py-2'>{isCount.Total_Users || "0"}</p>
                            <div className='flex items-center justify-start'>
                                <p className='font-medium text-lg'>Users</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='flex justify-between items-center fontNew my-5 mx-3'>
                        <p className='text-2xl font-semibold'>Shifts</p>
                        <div>
                            <FormSelect id="category" name="fuel_type" value={selectShift} onChange={(e) => setSelectShift(e.target.value)} className="select-arrow-hidden text-base font-medium w-60 outline-0">
                                <option value={0}>All</option>
                                <option value={1}>Approved</option>
                                <option value={2}>Clock In</option>
                                <option value={3}>Clock Out</option>
                                <option value={4}>Completed</option>
                                <option value={5}>Cancelled</option>
                            </FormSelect>
                        </div>
                    </div>
                    <div className='grid grid-cols-12 fontNew bg-white rounded-2xl'>
                        <div className='col-span-12 overflow-auto rounded-2xl'>
                            {
                                isLoader ?
                                    <div className='h-[585px] w-full flex items-center justify-center'>
                                        <Bars
                                            width={30}
                                            height={30}
                                            color="#000"
                                            ariaLabel="circles-loading"
                                            wrapperStyle={{}}
                                            visible={true}
                                            wrapperClass=""
                                        />
                                    </div>
                                    :
                                    <>
                                        {
                                            userData == "" ?
                                                <div className='h-[75vh] w-full flex items-center justify-center'>
                                                    <p className='text-center text-lg font-semibold text-gray-500'>Shift Data Not Found</p>
                                                </div>
                                                :
                                                <table className='w-full'>
                                                    <tr className='bg-gray-100 text-gray-500'>
                                                        <th className='p-4 text-base whitespace-nowrap text-start '>#</th>
                                                        <th className='p-4 text-base whitespace-nowrap text-start'>Professional</th>
                                                        <th className='p-4 text-base whitespace-nowrap text-start'>Subject</th>
                                                        <th className='p-4 text-base whitespace-nowrap text-start'>Date</th>
                                                        <th className='p-4 text-base whitespace-nowrap text-start'>Start & End Time</th>
                                                        <th className='p-4 text-base whitespace-nowrap text-start'>Location</th>
                                                        <th className='p-4 text-base whitespace-nowrap text-start'>Pay Rate</th>
                                                        <th className='p-4 text-base whitespace-nowrap text-start'>Earnings</th>
                                                    </tr>
                                                    {
                                                        userData.map((item, index) => {
                                                            return (
                                                                <tr className='bg-white text-sm font-semibold border-b border-zinc-100' key={index}>
                                                                    <td className='text-start p-4'>
                                                                        <div className='whitespace-nowrap'>
                                                                            {item.post_service_id || "-"}
                                                                        </div>
                                                                    </td>
                                                                    <td className='text-start p-4'>
                                                                        <div className='whitespace-nowrap'>
                                                                            {item.professional_first_name || "-"} {item.professional_last_name || "-"}
                                                                        </div>
                                                                    </td>
                                                                    <td className='text-start p-4'>
                                                                        <div className='whitespace-nowrap'>
                                                                            {item.service_name || "-"}
                                                                        </div>
                                                                    </td>
                                                                    <td className='text-start p-4'>
                                                                        <div className='whitespace-nowrap'>
                                                                            {moment(item.service_date).format("L") || "-"}
                                                                        </div>
                                                                    </td>
                                                                    <td className='text-start p-4'>
                                                                        <div className='whitespace-nowrap'>
                                                                            {moment(item.post_start_time).local().format("LT")}{" "}
                                                                            &{" "}
                                                                            {moment(item.post_end_time).local().format("LT")}
                                                                        </div>
                                                                    </td>
                                                                    <td className='text-start p-4'>
                                                                        <div className='w-32 overflow-hidden whitespace-nowrap text-ellipsis'>{item.apartment || "-"}</div>
                                                                    </td>
                                                                    <td className='text-start p-4'>
                                                                        <div className='whitespace-nowrap'>
                                                                            ${item.service_hourly_price || "0"}/hr
                                                                        </div>
                                                                    </td>
                                                                    <td className='text-start p-4 text-green-600'>
                                                                        <div className='whitespace-nowrap'>
                                                                            ${item.sub_amount || "0"}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </table>
                                        }
                                    </>
                            }
                        </div>
                        {
                            userData == "" ? ""
                                :
                                <div className='col-span-12 flex items-center justify-end lg:mx-10 px-3 py-4'>
                                    <div className='flex items-center cursor-pointer'>
                                        <button onClick={() => handlePageChange(pageNo - 1)} disabled={pageNo === 1}>
                                            <img src={leftbtn} className='w-9 mx-3' alt="" />
                                        </button>
                                        <div className="flex items-center">
                                            {renderPaginationButtonss()}
                                        </div>
                                        <button onClick={() => handlePageChange(pageNo + 1)} disabled={pageNo === totalPage}>
                                            <img src={rightbtn} className='w-9 mx-3' alt="" />
                                        </button>
                                    </div>
                                </div>
                        }

                    </div>
                </div>
            </div>
            <div className='col-span-12 lg:col-span-12 2xl:col-span-4 p-2'>
                {/* <div className='fontNew bg-center bg-cover w-full bg-cover rounded-3xl bg-no-repeat text-white md:py-14 md:px-7 py-10 px-10' style={{ backgroundImage: `url(${card})` }}>
                    <img src={email} className='w-14' alt="" />
                    <p className='text-3xl font-bold py-2'>Invite Users</p>
                    <p className='py-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                    <button className='bg-white my-2 py-3 px-4 text-sm font-medium rounded-full text-[#7424F2]' onClick={() => { setModalOpens(true); setCopy(false) }} >Invite User</button>
                </div> */}
                <div className='fontNew bg-white rounded-xl my-2 py-5 px-6'>
                    <p className='text-2xl font-semibold'>Clock In/Out</p>
                    <div className="mt-6">
                        {
                            isLoding ?
                                <div className='h-[585px] w-full flex items-center justify-center'>
                                    <Bars
                                        width={30}
                                        height={30}
                                        color="#000"
                                        ariaLabel="circles-loading"
                                        wrapperStyle={{}}
                                        visible={true}
                                        wrapperClass=""
                                    />
                                </div>
                                :
                                <>
                                    {
                                        notificationData == "" ?
                                            <p className='text-center text-lg font-semibold text-gray-500 h-[60vh] flex items-center justify-center'>Data Not Found</p>
                                            :
                                            <>
                                                {
                                                    notificationData.map((item, index) => {
                                                        return (
                                                            <div key={index} className="flex justify-between items-center fontNew my-5">
                                                                <div className='flex items-center'>
                                                                    <Zoom>
                                                                        <img src={item.profile_pic ? IMG_URL + item.profile_pic : noImage} className="w-12 h-12 rounded-full object-cover" alt="" />
                                                                    </Zoom>
                                                                    <div className='ps-3'>
                                                                        <p className='text-lg font-semibold'>{item.first_name || "-"} {item.last_name || "-"}</p>
                                                                        <p className='text-sm'>{item.service_name || "-"}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='text-end'>
                                                                    {
                                                                        item.clock_out == null ?
                                                                            <p className="text-sm w-20 text-gray-500">{moment(item.clock_out).fromNow()}</p>
                                                                            :
                                                                            <p className="text-sm w-20 text-gray-500">{moment(item.clock_in).fromNow()}</p>
                                                                    }
                                                                    {
                                                                        item.clock_out == null ?
                                                                            <p className='text-sm text-green-600 font-medium'>Clocked In</p>
                                                                            :
                                                                            <p className='text-sm text-red-500 font-medium'>Clocked Out</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </>
                                    }
                                </>
                        }
                    </div>
                    {
                        notificationData == "" ? ""
                            :
                            <div className='flex items-center justify-center lg:mx-10 px-3 py-3'>
                                <div className='flex items-center cursor-pointer'>
                                    <button onClick={() => handlePageChanges(page - 1)} disabled={page === 1}>
                                        <img src={leftbtn} className='w-9 mx-3' alt="" />
                                    </button>
                                    <div className="flex items-center">
                                        {renderPaginationButton()}
                                    </div>
                                    <button onClick={() => handlePageChanges(page + 1)} disabled={page === totalCount}>
                                        <img src={rightbtn} className='w-9 mx-3' alt="" />
                                    </button>
                                </div>
                            </div>
                    }

                </div>
            </div>

            <Dialog
                open={isModalOpens}
                onClose={() => setModalOpens(false)}
                size="md"
            >
                <Dialog.Panel>
                    <Dialog.Description className="grid grid-cols-12">
                        <div className='col-span-12 sm:col-span-12 text-center fontNew px-3 pb-3'>
                            <div onClick={() => { setModalOpens(false) }} className='flex justify-end mt-2 cursor-pointer'>
                                <img src={xbtn} className='w-8' alt="" />
                            </div>
                            <div className='flex justify-center my-3'>
                                <img src={grammerly} className='w-16' alt="" />
                            </div>
                            <div className='my-4'>
                                <p className='text-3xl font-bold pb-1'>Invite User</p>
                                <p className='text-xl font-medium text-gray-500'>Copy link from below to invite users</p>
                            </div>
                            <div className='flex justify-center items-center'>
                                <input type="text" value={linkData} className='bg-zinc-100 flex w-full p-2 mr-3 rounded-2xl border-2 border-slate-300 outline-none text-lg fontNew' placeholder='Email Address' />

                                <button type='submit' className='fontNew py-3 lg:w-30 w-auto px-6 rounded-2xl text-white font-medium text-base bg-[#7424F2]' onClick={() => handleCopy(linkData)} >
                                    {
                                        copy == true ? "Copied" : "Copy"
                                    }
                                </button>
                            </div>
                            <div className='flex justify-between mt-8 mb-2 px-4'>
                                <p className='font-medium text-base text-gray-500'>Social Media:</p>
                                <div className='flex'>
                                    <img src={facebook} className='px-2 w-12' alt="" />
                                    <img src={instagram} className='px-2 w-12' alt="" />
                                    <img src={snapchat} className='px-2 w-12' alt="" />
                                </div>
                            </div>

                        </div>
                    </Dialog.Description>
                </Dialog.Panel>
            </Dialog>
        </div >
    )
}

export default Dashboard

