import React, { useState, useEffect } from 'react'
import FramePeople from "../../Assets/Image/Frame.png"
import textName from "../../Assets/Image/text.png"
import textBlack from "../../Assets/Image/text black.png"
import tree from "../../Assets/Image/tree.png"
import Back from "../../Assets/Image/Backs.png"
import { useLocation, useNavigate } from 'react-router-dom'
import Key from "../../Assets/svg/key.svg"
import Eye from "../../Assets/svg/eye.svg"
import EyeSlash from "../../Assets/svg/eye-slash.svg"
import { resetPassword } from '../../Services/ApiServices'
import toast from 'react-hot-toast'
import { ThreeCircles } from 'react-loader-spinner'

function ResetPassword() {

    const navigate = useNavigate()
    const location = useLocation()
    const tokens = localStorage.getItem("UToken")

    const [eyes, setEyes] = useState(0)
    const [eyess, setEyess] = useState(0)
    const [texts, setTexts] = useState("password")
    const [textss, setTextss] = useState("password")
    const [isLoading, setIsLoading] = useState(false)
    const [formData, setFormData] = useState({})

    useEffect(() => {
        if (tokens) {
            navigate("/dashboard")
        }
    }, [])

    let mail;
    try {
        const { Email } = location.state;
        mail = Email
    } catch (error) {
        console.log("err");
    }

    const handleEyes = () => {
        const flag = eyes == 0 ? 1 : 0
        setEyes(flag)

        const text = texts == "password" ? "text" : "password"
        setTexts(text)
    }
    const handleEyess = () => {
        const flag = eyess == 0 ? 1 : 0
        setEyess(flag)

        const text = textss == "password" ? "text" : "password"
        setTextss(text)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setIsLoading(true)
        let param = {
            email_id: mail,
            new_pass: formData.password
        }
        if (formData.password != formData.confirm_pass) {
            toast.error("Password and Confirm Password Not Match")
            setTimeout(() => {
                setIsLoading(false)
            }, 1000);
        } else {
            resetPassword(param)
                .then((res) => {
                    console.log("res >>", res);
                    const status = res.data.Status;
                    if (status == 1) {
                        console.log("verification >>", res.data);
                        navigate("/")
                    } else {
                        toast.error(res.data.Message)
                    }
                    setIsLoading(false)
                })
                .catch((err) => {
                    console.log("err >>", err);
                    setIsLoading(false)
                })
        }

    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }

    return (
        <div className="grid grid-cols-12 gap-x-6 bg-white">
            <div className='col-span-12 intro-y md:col-span-6 bg-black h-[100vh] md:flex items-end justify-center hidden'>
                <div>
                    <div className='flex justify-center pb-52'>
                        <img src={textName} className='w-60' alt="" />
                    </div>
                    <div className='flex h-[400px] md:h-[500px] 2xl:col-span-6 ms-10 relative'>
                        <img src={FramePeople} className='' alt="" />
                        <img src={tree} className='xl:block hidden' alt="" />
                    </div>
                </div>
            </div>
            <div className='col-span-12 intro-y md:col-span-6 '>
                <div>
                    <div className='py-6 px-6 md:px-0'>
                        <img src={Back} width={55} onClick={() => { navigate("/verification") }} alt="" />
                    </div>
                    <div className='text-center flex flex-col justify-between items-center h-[85vh]'>
                        <div className='md:mt-40 mt-10'>
                            <form onSubmit={handleSubmit}>
                                <div className='flex justify-center mb-10 md:hidden block'>
                                    <img src={textBlack} className='w-52' alt="" />
                                </div>
                                <p className='fontNew text-4xl font-bold p-3'>Reset Password</p>
                                <p className='fontNew text-base text-slate-500'>Reset your password</p>
                                <div className='mt-10 mb-6'>
                                    <div className='bg-zinc-100 flex p-2 rounded-2xl border-2 border-slate-300 my-3'>
                                        <img src={Key} className='p-2 w-11' alt="" />
                                        <input
                                            onChange={(e) => handleChange(e)}
                                            type={texts}
                                            className='bg-transparent border-none outline-none w-full text-lg fontNew'
                                            required
                                            max={20}
                                            min={8}
                                            placeholder='New Password'
                                            name='password'
                                        />
                                        {
                                            eyes == 0 ?
                                                <img src={EyeSlash} onClick={handleEyes} className='p-2 w-10 cursor-pointer' alt="" />
                                                :
                                                <img src={Eye} onClick={handleEyes} className='p-2 w-10 cursor-pointer' alt="" />
                                        }
                                    </div>
                                    <div className='bg-zinc-100 flex p-2 rounded-2xl border-2 border-slate-300 my-3'>
                                        <img src={Key} className='p-2 w-11' alt="" />
                                        <input
                                            onChange={(e) => handleChange(e)}
                                            type={textss}
                                            className='bg-transparent border-none outline-none w-full text-lg fontNew'
                                            required
                                            max={20}
                                            min={8}
                                            placeholder='Confirm Password'
                                            name='confirm_pass'
                                        />
                                        {
                                            eyess == 0 ?
                                                <img src={EyeSlash} onClick={handleEyess} className='p-2 w-10 cursor-pointer' alt="" />
                                                :
                                                <img src={Eye} onClick={handleEyess} className='p-2 w-10 cursor-pointer' alt="" />
                                        }
                                    </div>
                                </div>
                                <div>
                                    <button type='submit' className='fontNew py-5 w-full rounded-2xl text-white font-bold text-base' style={{ backgroundColor: "#7424F2" }}>
                                        {
                                            isLoading ?
                                                <div className='flex justify-center'>
                                                    <ThreeCircles
                                                        width={23}
                                                        height={23}
                                                        color="#fff"
                                                        ariaLabel="circles-loading"
                                                        wrapperStyle={{}}
                                                        visible={true}
                                                        wrapperClass=""
                                                    />
                                                </div>
                                                :
                                                "Confirm"
                                        }
                                    </button>
                                </div>
                            </form>
                        </div>

                        <div className='p-3'>
                            <p className='text-lg font-bold fontNew cursor-pointer' onClick={() => { navigate("/") }} style={{ color: "#7424F2" }}>Back to Log in</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword