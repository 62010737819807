import axios from "axios";
import {
    ADD_ADDRESS_API,
    ADD_POST_ADMIN,
    CHANGE_PRO_STATUS_API,
    CLOCK_IN_SHIFT,
    CLOCK_OUT_SHIFT,
    DELETE_ACCOUNT,
    DELETE_ACCOUNT_API,
    EARNING_DASHBOARD_API,
    EDIT_ADDRESS_API,
    FORGOT_PASSWORD_API,
    GET_CLOCK_LIST_API,
    GET_EDIT_MY_PROFILE,
    GET_SHIFT_LIST_API,
    LIST_ADDRESS_API,
    LIST_ADMIN_PAYMENT_API,
    LIST_NOTIFICATION_API,
    LIST_SKILL_PROFESSIONAL,
    PAYMENT_BY_ADMIN,
    PAYMENT_BY_MANUALLY_ADMIN,
    PROFESSIONAL_LIST_API,
    RESET_PASSWORD_API,
    SEARCH_PROFESSIONAL_LIST_API,
    SEARCH_SKILL_PROFESSIONAL,
    SEARCH_USERS_LIST_API,
    SEND_NOTIFICATION_API,
    SKILL_ACTIVATE_API,
    USERS_LIST_API,
    VERIFICATION_EMAIL_API
} from "./Api";


axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("UToken");
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

const headers = {
    "Contrent-type": "application/josn",
};

const forgotPassword = (param) => {
    return axios.post(FORGOT_PASSWORD_API, param, {
        headers: headers
    })
}

const verificationEmail = (param) => {
    return axios.post(VERIFICATION_EMAIL_API, param, {
        headers: headers
    })
}

const resetPassword = (param) => {
    return axios.post(RESET_PASSWORD_API, param, {
        headers: headers
    })
}

const getUserList = (param) => {
    return axios.post(USERS_LIST_API, param, {
        headers: headers
    })
}

const getSearchUser = (param) => {
    return axios.post(SEARCH_USERS_LIST_API, param, {
        headers: headers
    })
}

const deleteAccounts = (param) => {
    return axios.post(DELETE_ACCOUNT, param, {
        headers: headers
    })
}

const getProfessionalList = (param) => {
    return axios.post(PROFESSIONAL_LIST_API, param, {
        headers: headers
    })
}

const getSearchProfessional = (param) => {
    return axios.post(SEARCH_PROFESSIONAL_LIST_API, param, {
        headers: headers
    })
}

const changeProfileStatus = (param) => {
    return axios.post(CHANGE_PRO_STATUS_API, param, {
        headers: headers
    })
}

const getShiftList = (param) => {
    return axios.post(GET_SHIFT_LIST_API, param, {
        headers: headers
    })
}

const getClockList = (param) => {
    return axios.post(GET_CLOCK_LIST_API, param, {
        headers: headers
    })
}

const getPaymentList = (param) => {
    return axios.post(LIST_ADMIN_PAYMENT_API, param, {
        headers: headers
    })
}

const adminByPayment = (param) => {
    return axios.post(PAYMENT_BY_ADMIN, param, {
        headers: headers
    })
}

const adminMenuallyByPayment = (param) => {
    return axios.post(PAYMENT_BY_MANUALLY_ADMIN, param, {
        headers: headers
    })
}

const sendnotificationData = (param) => {
    return axios.post(SEND_NOTIFICATION_API, param, {
        headers: headers
    })
}

const editProfile = (param) => {
    return axios.post(GET_EDIT_MY_PROFILE, param, {
        headers: headers
    })
}

const skillActivate = (param) => {
    return axios.post(SKILL_ACTIVATE_API, param, {
        headers: headers
    })
}

const clockInShift = (param) => {
    return axios.post(CLOCK_IN_SHIFT, param, {
        headers: headers
    })
}

const clockOutShift = (param) => {
    return axios.post(CLOCK_OUT_SHIFT, param, {
        headers: headers
    })
}

const listAddress = (param) => {
    return axios.post(LIST_ADDRESS_API, param, {
        headers: headers
    })
}

const addAddressApi = (param) => {
    return axios.post(ADD_ADDRESS_API, param, {
        headers: headers
    })
}

const editAddressApi = (param) => {
    return axios.post(EDIT_ADDRESS_API, param, {
        headers: headers
    })
}

const listSkillProfessional = (param) => {
    return axios.post(LIST_SKILL_PROFESSIONAL, param, {
        headers: headers
    })
}

const searchSkillProfessional = (param) => {
    return axios.post(SEARCH_SKILL_PROFESSIONAL, param, {
        headers: headers
    })
}

const addPostAdmin = (param) => {
    return axios.post(ADD_POST_ADMIN, param, {
        headers: headers
    })
}

const listNotification = (param) => {
    return axios.post(LIST_NOTIFICATION_API, param, {
        headers: headers
    })
}

export {
    forgotPassword,
    verificationEmail,
    resetPassword,
    getUserList,
    getSearchUser,
    deleteAccounts,
    getProfessionalList,
    getSearchProfessional,
    changeProfileStatus,
    getShiftList,
    getClockList,
    getPaymentList,
    adminByPayment,
    adminMenuallyByPayment,
    sendnotificationData,
    editProfile,
    skillActivate,
    clockInShift,
    clockOutShift,
    listAddress,
    addAddressApi,
    editAddressApi,
    listSkillProfessional,
    searchSkillProfessional,
    addPostAdmin,
    listNotification,
}