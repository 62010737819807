import React, { useEffect, useState } from 'react'
import clsx from "clsx"
import info from '../../Assets/Image/Delete.png'
import tick from '../../Assets/svg/tick-circle.svg'
import close from '../../Assets/svg/close-circle.svg'
import card from '../../Assets/Image/purplecard.png'
import { changeProfileStatus, deleteAccounts, getProfessionalList, getSearchProfessional, skillActivate } from '../../Services/ApiServices'
import leftbtn from '../../Assets/Image/left btn.png'
import rightbtn from '../../Assets/Image/right btn.png'
import { IMG_URL } from '../../Services/Api'
import noImage from "../../Assets/Image/noImage.png";
import { Bars, ThreeCircles } from 'react-loader-spinner'
import Dialog from '../../Base-Component/Dialog/Dialog'
import Zoom from 'react-medium-image-zoom'
import FormSelect from '../../Base-Component/FormSelect/FormSelect'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'

function Professional() {

    const navigate = useNavigate()
    const tokens = localStorage.getItem("UToken")

    const [isFlag, setIsFlag] = useState(0)
    const [isFlags, setIsFlags] = useState(0)
    const [pageNo, setPageNo] = useState(1)
    const [pages, setPages] = useState(1)
    const [subType, setSubType] = useState(0)
    const [subTypes, setSubTypes] = useState(0)
    const [isType, setIsType] = useState(0)

    const [activeservicid, setActiveservicid] = useState();
    const [totalPage, setTotalPage] = useState()
    const [totalCount, setTotalCount] = useState()

    const [search, setSearch] = useState("")
    const [userId, setUserId] = useState("")
    const [rejectData, setRejectData] = useState("")

    const [userDetails, setUserDetails] = useState({})
    const [skillDetails, setSkillDetails] = useState({})

    const [professionalData, setProfessionalData] = useState([])
    const [professionalDatas, setProfessionalDatas] = useState([])

    const [isLoader, setIsLoader] = useState(false)
    const [isLoding, setIsLoding] = useState(false)
    const [isModalOpens, setModalOpens] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [isFormModal, setIsFormModal] = useState(false);
    const [isTerminated, setIsTerminated] = useState(false);
    const [isSkillAccept, setIsSkillAccept] = useState(false)
    const [isSkillReject, setIsSkillReject] = useState(false)
    const [isDelete, setIsDelete] = useState(false);

    useEffect(() => {
        setIsLoader(true)
        handleProfessional()
    }, [search, pages, subType])

    useEffect(() => {
        if (!tokens) {
            navigate("/login")
        }
    }, [])

    useEffect(() => {
        if (isFlag == 1) {
            setIsFlag(1)
            handleProfessionals()
            setIsLoader(true)
        }
    }, [search, pages, subTypes])

    const handleProfessional = () => {
        if (!search) {
            let param = {
                type: 0,
                page_no: pageNo,
                sub_type: subType
            }
            getProfessionalList(param)
                .then((res) => {
                    const status = res.data.Status;
                    const data = res.data.info;
                    if (status == 1) {
                        setProfessionalData(data)
                        setTotalPage(res.data.total_page)
                    } else {
                        console.log("error >>", res.data.Message);
                    }
                    setIsLoader(false)
                })
                .catch((err) => {
                    console.log("err >>", err);
                    setIsLoader(false)
                })
        } else {
            let param = {
                type: 0,
                search_text: search,
                sub_type: subType
            }
            getSearchProfessional(param)
                .then((res) => {
                    const status = res.data.Status;
                    const data = res.data.info;
                    if (status == 1) {
                        setProfessionalData(data)
                    } else {
                        console.log("error >>", res.data.Message);
                    }
                    setIsLoader(false)
                })
                .catch((err) => {
                    console.log("err >>", err);
                    setIsLoader(false)
                })
        }
    }

    const handleProfessionals = () => {
        setIsLoader(true)
        if (!search) {
            let param = {
                type: 1,
                page_no: pages,
                sub_type: subTypes
            }
            getProfessionalList(param)
                .then((res) => {
                    const status = res.data.Status;
                    const data = res.data.info;
                    if (status == 1) {
                        setProfessionalDatas(data)
                        setTotalCount(res.data.total_page)
                    } else {
                        console.log("error >>", res.data.Message);
                    }
                    setIsLoader(false)
                })
                .catch((err) => {
                    console.log("err >>", err);
                    setIsLoader(false)
                })
        } else {
            let param = {
                type: 1,
                search_text: search,
                sub_type: subTypes
            }
            getSearchProfessional(param)
                .then((res) => {
                    const status = res.data.Status;
                    const data = res.data.info;
                    if (status == 1) {
                        setProfessionalDatas(data)
                        setTotalCount(res.data.total_page)
                    } else {
                        console.log("error >>", res.data.Message);
                    }
                    setIsLoader(false)
                })
                .catch((err) => {
                    console.log("err >>", err);
                    setIsLoader(false)
                })
        }
    }

    const handleDetails = (data) => {
        setUserDetails(data)
        setModalOpens(true)
    }

    const handleDetail = (data) => {
        setUserDetails(data)
        setModalOpen(true)
    }

    const handleDetailss = (data) => {
        setUserDetails(data)
        setIsTerminated(true)
    }

    const renderPaginationButtons = () => {
        const maxPagesToShow = 3;
        const buttons = [];
        const startPage = Math.max(1, pages - Math.floor(maxPagesToShow / 2));
        const endPage = Math.min(totalCount, startPage + maxPagesToShow - 1);

        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <button
                    key={i}
                    onClick={() => handlePageChange(i)}
                    className={i === pages ? 'px-3 py-1 mx-1 bg-black rounded-full text-white' : 'px-3 py-1 mx-1'}
                >
                    {i}
                </button>
            );
        }
        return buttons;
    };

    const handlePageChange = (page) => {
        setPages(page);
    };

    const renderPaginationButtonss = () => {
        const maxPagesToShow = 3;
        const buttons = [];
        const startPage = Math.max(1, pageNo - Math.floor(maxPagesToShow / 2));
        const endPage = Math.min(totalPage, startPage + maxPagesToShow - 1);

        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <button
                    key={i}
                    onClick={() => handlePageChange(i)}
                    className={i === pageNo ? 'px-3 py-1 mx-1 bg-black rounded-full text-white' : 'px-3 py-1 mx-1'}
                >
                    {i}
                </button>
            );
        }
        return buttons;
    };

    const handlePageChanges = (page) => {
        setPageNo(page);
    };

    const handleAccept = (type) => {
        if (type == 1) {
            setIsLoding(true)
            let param = {
                user_id: userId,
                is_profile_verify: 1
            }
            changeProfileStatus(param)
                .then((res) => {
                    const status = res.data.Status;
                    if (status == 1) {
                        console.log("res >>", res.data.Message);
                        setModalOpens(false)
                        if (isFlag == 0) {
                            handleProfessional()
                        } else {
                            handleProfessionals()
                            setIsFlags(0)
                        }
                    } else {
                        console.log("error", res.data.Message);
                    }
                    setIsLoding(false)
                })
                .catch((err) => {
                    console.log("err >>", err);
                    setIsLoding(false)
                })
        } else if (type == 2) {
            setIsLoding(true)
            let param = {
                user_id: userId,
                is_profile_verify: 2,
                reject_reason: rejectData
            }
            changeProfileStatus(param)
                .then((res) => {
                    const status = res.data.Status;
                    if (status == 1) {
                        console.log("res >>", res.data.Message);
                        setModalOpen(false)
                        handleProfessional()
                    } else {
                        console.log("error", res.data.Message);
                    }
                    setIsLoding(false)
                })
                .catch((err) => {
                    console.log("err >>", err);
                    setIsLoding(false)
                })
        } else if (type == 3) {
            setIsLoding(true)
            let param = {
                user_id: userId,
                is_profile_verify: 3
            }
            changeProfileStatus(param)
                .then((res) => {
                    const status = res.data.Status;
                    if (status == 1) {
                        console.log("res >>", res.data.Message);
                        setIsTerminated(false)
                        if (isFlag == 0) {
                            handleProfessional()
                        } else {
                            handleProfessionals()
                            setIsFlags(0)
                        }
                    } else {
                        console.log("error", res.data.Message);
                    }
                    setIsLoding(false)
                })
                .catch((err) => {
                    console.log("err >>", err);
                    setIsLoding(false)
                })
        }
    }

    const skillAccept = (data) => {
        console.log(data);
        setSkillDetails(data)
        setIsSkillAccept(true)
    }

    const skillReject = (data) => {
        console.log(data);
        setSkillDetails(data)
        setIsSkillReject(true)
    }

    const handleSkills = (type) => {
        if (type == 1) {
            setIsLoding(true)
            let param = {
                user_services_id: userId,
                is_active_skill: 1
            }
            skillActivate(param)
                .then((res) => {
                    const status = res.data.Status;
                    if (status == 1) {
                        console.log("res >>", res.data.Message);
                        setIsSkillAccept(false)
                        handleProfessionals()
                        setIsFlags(0)
                    } else {
                        console.log("error", res.data.Message);
                    }
                    setIsLoding(false)
                })
                .catch((err) => {
                    console.log("err >>", err);
                    setIsLoding(false)
                })
        } else if (type == 2) {
            setIsLoding(true)
            let param = {
                user_services_id: userId,
                is_active_skill: 2
            }
            skillActivate(param)
                .then((res) => {
                    const status = res.data.Status;
                    if (status == 1) {
                        console.log("res >>", res.data.Message);
                        setIsSkillReject(false)
                        handleProfessionals()
                        setIsFlags(0)
                    } else {
                        console.log("error", res.data.Message);
                    }
                    setIsLoding(false)
                })
                .catch((err) => {
                    console.log("err >>", err);
                    setIsLoding(false)
                })
        }
    }

    const handleDelete = () => {
        setIsLoding(true)
        let obj = {
            user_id: userId
        }
        deleteAccounts(obj)
            .then((res) => {
                const status = res.data.Status;
                if (status == 1) {
                    toast.success(res.data.Message)
                    setIsDelete(false)
                    if (isFlag == 0) {
                        handleProfessional()
                    } else {
                        handleProfessionals()
                    }
                } else {
                    toast.error(res.data.Message)
                }
                setIsLoding(false)
            })
            .catch((err) => {
                console.log("err >>", err)
                setIsLoding(false)
            })
    }

    return (
        <div className='grid grid-cols-12 gap-3'>
            <div className='col-span-12 lg:col-span-12 2xl:col-span-9'>
                <div className='bg-white rounded-xl fontNew'>
                    <div className='flex flex-wrap items-center justify-between px-6 border-b-2 border-gray-100'>
                        <div className='flex items-center font-semibold md:text-xl text-lg cursor-pointer'>
                            <p
                                className={clsx([isFlag == 0 ?
                                    "md:px-12 px-5 text-[#7424F2] border-b-[3px] border-[#7424F2] py-6"
                                    : "md:px-12 px-5 text-gray-500 border-b-[3px] border-white"])}
                                onClick={() => { setIsFlag(0); handleProfessional(); setIsFlags(0); setIsType(0) }}
                            >
                                Requests
                            </p>
                            <p
                                className={clsx([isFlag == 1 ?
                                    "px-12 text-[#7424F2] border-b-[3px] border-[#7424F2] py-6"
                                    : "px-12 text-gray-500 border-b-[3px] border-white"])}
                                onClick={() => { setIsFlag(1); handleProfessionals(); setIsLoader(true); setIsFlags(0); setIsType(1) }}
                            >
                                Professionals
                            </p>
                        </div>
                        <div className='py-4 flex flex-wrap'>
                            {
                                isType == 0 ?
                                    <div className='mx-2 lg:my-0 my-2'>
                                        <FormSelect id="category" onChange={(e) => setSubType(e.target.value)} value={subType} name="fuel_type" className="select-arrow-hidden text-base font-medium w-32 py-2 rounded-lg outline-0 bg-[#d3d3d366]">
                                            <option value={0}>All</option>
                                            <option value={1}>Ongoing</option>
                                            <option value={2}>Rejected</option>
                                        </FormSelect>
                                    </div>
                                    :
                                    <div className='mx-2 lg:my-0 my-2'>
                                        <FormSelect id="category" onChange={(e) => setSubTypes(e.target.value)} value={subTypes} name="fuel_type" className="select-arrow-hidden text-base font-medium w-32 py-2 rounded-lg outline-0 bg-[#d3d3d366]">
                                            <option value={0}>All</option>
                                            <option value={1}>Completed</option>
                                            <option value={2}>Terminated</option>
                                        </FormSelect>
                                    </div>
                            }
                            <input type="search" placeholder='Search Professionals....' onChange={(e) => setSearch(e.target.value)} className='w-52 border-2 px-3 py-2 rounded-lg text-sm outline-0' />
                        </div>
                    </div>
                    <div className='pt-6 px-3 '>
                        {
                            isFlag == 0 ?
                                <>
                                    {
                                        isLoader ?
                                            <div className='h-[75vh] flex items-center justify-center'>
                                                <Bars
                                                    width={30}
                                                    height={30}
                                                    color="#000"
                                                    ariaLabel="circles-loading"
                                                    wrapperStyle={{}}
                                                    visible={true}
                                                    wrapperClass=""
                                                />
                                            </div>
                                            :
                                            <div>
                                                {
                                                    professionalData == "" ?
                                                        <p className='text-center text-lg font-semibold text-gray-500 h-[75vh] flex items-center justify-center'>Data Not Found</p>
                                                        :
                                                        <>
                                                            <div className='flex flex-wrap'>
                                                                {
                                                                    professionalData.map((item, index) => {
                                                                        return (
                                                                            <div key={index}
                                                                                className={parseInt(item.user_id) === parseInt(activeservicid) ?
                                                                                    'md:w-64 w-full bg-gray-100 rounded-xl m-2 cursor-pointer border-[#7424F2] border-2 hover:drop-shadow-lg duration-300'
                                                                                    : "md:w-64 w-full bg-gray-100 rounded-xl m-2 cursor-pointer border-white border-2 hover:drop-shadow-lg duration-300"}>
                                                                                <div className='flex items-center justify-between border-b-2 border-white p-4'>
                                                                                    <div className='flex items-center'
                                                                                        onClick={() => { setActiveservicid(item.user_id); setIsFlags(1); setUserDetails(item) }}
                                                                                    >
                                                                                        <Zoom>
                                                                                            <img src={item.profile_pic ? IMG_URL + item.profile_pic : noImage} className='w-12 h-12 object-cover rounded-full' alt="" />
                                                                                        </Zoom>
                                                                                        <div className='ms-2'>
                                                                                            <p className='text-base font-semibold'>{item.first_name || "-"} {item.last_name || "-"}</p>
                                                                                            <p className='text-sm'>
                                                                                                {
                                                                                                    item.service_list == "" ? ""
                                                                                                        :
                                                                                                        <p>{item.service_list[0].service_name}</p>
                                                                                                }
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <img src={info} className='h-[16px]' onClick={() => { setUserDetails(item); setIsDelete(true); setUserId(item.user_id) }} alt="" />
                                                                                </div>
                                                                                {
                                                                                    item.is_profile_verify == 2 ?
                                                                                        <div className='flex items-center justify-cenetr'>
                                                                                            <div className='flex justify-center w-full items-center p-4'>
                                                                                                <img src={close} alt="" />
                                                                                                <p className='text-red-500 ms-1 text-sm font-semibold'>Rejected</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        <div className='flex items-center justify-around'>
                                                                                            <div className='flex justify-center w-full items-center border-r-2 border-white p-4' onClick={() => { handleDetails(item); setUserId(item.user_id) }} >
                                                                                                <img src={tick} alt="" />
                                                                                                <p className='text-green-500 ms-1 text-sm font-semibold'>Accept</p>
                                                                                            </div>
                                                                                            <div className='flex justify-center w-full items-center p-4' onClick={() => { handleDetail(item); setUserId(item.user_id); setRejectData("") }}>
                                                                                                <img src={close} alt="" />
                                                                                                <p className='text-red-500 ms-1 text-sm font-semibold'>Reject</p>
                                                                                            </div>
                                                                                        </div>
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            <div className='flex items-center justify-end lg:mx-10 px-3 py-4'>
                                                                <div className='flex items-center cursor-pointer'>
                                                                    <button onClick={() => handlePageChanges(pageNo - 1)} disabled={pageNo === 1}>
                                                                        <img src={leftbtn} className='w-9 mx-3' alt="" />
                                                                    </button>
                                                                    <div className="flex items-center">
                                                                        {renderPaginationButtonss()}
                                                                    </div>
                                                                    <button onClick={() => handlePageChanges(pageNo + 1)} disabled={pageNo === totalPage}>
                                                                        <img src={rightbtn} className='w-9 mx-3' alt="" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </>
                                                }
                                            </div>
                                    }
                                </>
                                :
                                <>
                                    {
                                        isLoader ?
                                            <div className='h-[75vh] flex items-center justify-center'>
                                                <Bars
                                                    width={30}
                                                    height={30}
                                                    color="#000"
                                                    ariaLabel="circles-loading"
                                                    wrapperStyle={{}}
                                                    visible={true}
                                                    wrapperClass=""
                                                />
                                            </div>
                                            :
                                            <div>
                                                {
                                                    professionalDatas == "" ?
                                                        <p className='text-center text-lg font-semibold text-gray-500 h-[75vh] flex items-center justify-center'>Data Not Found</p>
                                                        :
                                                        <>
                                                            <div className='flex flex-wrap'>
                                                                {
                                                                    professionalDatas.map((item, index) => {
                                                                        return (
                                                                            <div key={index}
                                                                                className={parseInt(item.user_id) === parseInt(activeservicid) ?
                                                                                    'md:w-64 w-full bg-gray-100 rounded-xl m-2 cursor-pointer border-[#7424F2] border-2 hover:drop-shadow-lg duration-300'
                                                                                    : "md:w-64 w-full bg-gray-100 rounded-xl m-2 cursor-pointer border-white border-2 hover:drop-shadow-lg duration-300"}
                                                                            >
                                                                                <div className='flex items-center justify-between m-4'>
                                                                                    <div className='flex items-center'
                                                                                        onClick={() => { setActiveservicid(item.user_id); setIsFlags(1); setUserDetails(item) }}
                                                                                    >
                                                                                        <Zoom>
                                                                                            <img src={item.profile_pic ? IMG_URL + item.profile_pic : noImage} className='w-12 h-12 object-cover rounded-full' alt="" />
                                                                                        </Zoom>
                                                                                        <div className='ms-2'>
                                                                                            <p className='text-base font-semibold'>{item.first_name || "-"} {item.last_name || "-"}</p>
                                                                                            <p className='text-sm'>
                                                                                                {
                                                                                                    item.service_list == "" ? ""
                                                                                                        :
                                                                                                        <p>{item.service_list[0].service_name}</p>
                                                                                                }
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <img src={info} className='h-[16px]' onClick={() => { setUserDetails(item); setIsDelete(true); setUserId(item.user_id) }} alt="" />
                                                                                </div>
                                                                                {
                                                                                    item.is_profile_verify == 3 ?
                                                                                        <div className='flex items-center justify-cenetr'>
                                                                                            <div className='flex justify-center w-full items-center p-4'>
                                                                                                <img src={close} alt="" />
                                                                                                <p className='text-red-500 ms-1 text-sm font-semibold'>Terminated</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        : item.is_profile_verify == 4 ?
                                                                                            <div className='flex items-center justify-around'>
                                                                                                <div className='flex justify-center w-full items-center border-r-2 border-white p-4' onClick={() => { handleDetails(item); setUserId(item.user_id) }} >
                                                                                                    <img src={tick} alt="" />
                                                                                                    <p className='text-green-500 ms-1 text-sm font-semibold'>Accept</p>
                                                                                                </div>
                                                                                                <div className='flex justify-center w-full items-center p-4' >
                                                                                                    <img src={close} alt="" />
                                                                                                    <p className='text-red-500 ms-1 text-sm font-semibold'>Terminated</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            <div className='flex items-center justify-around m-4'>
                                                                                                <div>
                                                                                                    <p className='text-xs'>Completed Shift</p>
                                                                                                    <p className='text-sm font-semibold'>$ {item.Total_shift || "0"}</p>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <p className='text-xs'>Total Earnings</p>
                                                                                                    <p className='text-sm font-semibold'>$ {item.Total_earning || "0"}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            <div className='flex items-center justify-end lg:mx-10 px-3 py-4'>
                                                                <div className='flex items-center cursor-pointer'>
                                                                    <button onClick={() => handlePageChange(pages - 1)} disabled={pages === 1}>
                                                                        <img src={leftbtn} className='w-9 mx-3' alt="" />
                                                                    </button>
                                                                    <div className="flex items-center">
                                                                        {renderPaginationButtons()}
                                                                    </div>
                                                                    <button onClick={() => handlePageChange(pages + 1)} disabled={pages === totalCount}>
                                                                        <img src={rightbtn} className='w-9 mx-3' alt="" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </>
                                                }
                                            </div>
                                    }
                                </>
                        }
                    </div>
                </div>
            </div>
            <div className='col-span-12 lg:col-span-12 2xl:col-span-3'>
                {
                    isFlags == 0 ? ""
                        :
                        <div>
                            <div className='bg-white rounded-xl fontNew'>
                                <img src={card} className='w-full h-30 object-cover rounded-t-xl' alt="" />
                                <div className='text-center'>
                                    <div className='flex justify-center'>
                                        <Zoom>
                                            <img src={userDetails.profile_pic ? IMG_URL + userDetails.profile_pic : noImage} className='h-30 w-30 rounded-full object-cover -mt-15' alt="" />
                                        </Zoom>
                                    </div>
                                    <p className='text-lg font-bold mt-2'>{userDetails.first_name || "-"} {userDetails.last_name || "-"}</p>
                                </div>
                                <div className='px-4 py-3'>
                                    <div className='py-2 border-b-2 border-gray-100'>
                                        <button className='bg-gray-200 py-2 px-5 text-gray-500 font-bold rounded-full text-xs my-3'>Contact Info</button>
                                        <div className='flex justify-between py-2'>
                                            <p className='font-medium text-gray-500 text-sm'>Preferred Name:</p>
                                            <p className='font-bold text-sm'>{userDetails.first_name || "-"} {userDetails.last_name || "-"}</p>
                                        </div>
                                        <div className='flex justify-between py-2'>
                                            <p className='font-medium text-gray-500 text-sm'>Email:</p>
                                            <p className='font-bold text-sm'>{userDetails.email_id || "-"}</p>
                                        </div>
                                        <div className='flex justify-between py-2'>
                                            <p className='font-medium text-gray-500 text-sm'>Mobile Number:</p>
                                            <p className='font-bold text-sm'>{userDetails.country_code || "-"} {userDetails.mobile_number || "-"}</p>
                                        </div>
                                        <div className='flex justify-between py-2'>
                                            <p className='font-medium text-gray-500 text-sm'>Address:</p>
                                            <p className='font-bold text-sm text-end'>{userDetails.address_line1 || ""} {userDetails.address_line2 || ""} {userDetails.zip_code || ""}</p>
                                        </div>
                                    </div>
                                    <div className='py-2 border-b-2 border-gray-100'>
                                        <button className='bg-gray-200 py-2 px-5 text-gray-500 font-bold rounded-full text-xs my-3'>Emergency Contact</button>
                                        <div className='flex justify-between py-2'>
                                            <p className='font-medium text-gray-500 text-sm'>Preferred Name:</p>
                                            <p className='font-bold text-sm'>{userDetails.emergency_fullname || "-"}</p>
                                        </div>
                                        <div className='flex justify-between py-2'>
                                            <p className='font-medium text-gray-500 text-sm'>Mobile Number:</p>
                                            <p className='font-bold text-sm'>{userDetails.emergency_contact_countrycode || "-"} {userDetails.emergency_contactnumber || "-"}</p>
                                        </div>
                                    </div>
                                    <div className='py-2'>
                                        <button className='bg-gray-200 py-2 px-5 text-gray-500 font-bold rounded-full text-xs my-3'>Skill & Documents</button>
                                        {
                                            userDetails.service_list?.map((item, index) => {
                                                return (
                                                    <div key={index} className='flex justify-between py-2'>
                                                        <div>
                                                            <p className='font-medium text-gray-500 text-sm'>{item.service_name || ""}</p>
                                                            {
                                                                item.is_active_skill == 0 ?
                                                                    <div className='flex justify-center items-center py-3'>
                                                                        <button className='bg-[#038a001a] text-[#038A00] w-28 text-sm rounded-full py-2 font-semibold text-base mx-1' onClick={() => { skillAccept(item); setUserId(item.user_services_id) }}>Accept</button>
                                                                        <button className='bg-[#e423231a] rounded-full w-28 text-sm py-2 text-[#E42323] font-semibold text-base mx-1' onClick={() => { skillReject(item); setUserId(item.user_services_id) }}>Reject</button>
                                                                    </div>
                                                                    : ""
                                                            }
                                                        </div>
                                                        {
                                                            item.category_image ?
                                                                <Zoom>
                                                                    <img src={IMG_URL + item.category_image} className='w-12 h-12 object-cover rounded-lg' alt="" />
                                                                </Zoom>
                                                                : ""
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                        {/* <div className='flex justify-between py-2'>
                                            <p className='font-medium text-gray-500 text-sm'>W-9 Form :</p>
                                            <div className='flex items-center cursor-pointer' onClick={() => setIsFormModal(true)}>
                                                <img src={Eye} className='w-5 mr-1' alt="" />
                                                <p className='font-medium'>View</p>
                                            </div>
                                        </div> */}
                                    </div>
                                    {
                                        isFlag == 1 ?
                                            <>
                                                {
                                                    userDetails.is_profile_verify == 3 || userDetails.is_profile_verify == 4 ?
                                                        <div className='flex items-center justify-cenetr'>
                                                            <div className='flex justify-center w-full items-center p-4'>
                                                                <img src={close} alt="" />
                                                                <p className='text-red-500 ms-1 text-sm font-semibold'>Terminated</p>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='flex justify-center items-center py-3'>
                                                            <button className='bg-[#FFD6D6] text-[#E42323] w-30 text-sm rounded-full py-3 font-semibold text-base mx-3' onClick={() => { handleDetailss(userDetails); setUserId(userDetails.user_id) }}>Terminate</button>
                                                        </div>
                                                }
                                            </>
                                            : ""
                                    }
                                </div>
                            </div>
                        </div>
                }
            </div>

            <Dialog
                open={isModalOpens}
                onClose={() => setModalOpens(false)}
                size="md"
            >
                <Dialog.Panel>
                    <Dialog.Description className="grid grid-cols-12">
                        <div className='col-span-12 sm:col-span-12 text-center fontNew lg:px-12 px-7 pb-3'>
                            <div className='flex justify-center my-3'>
                                <img src={userDetails.profile_pic ? IMG_URL + userDetails.profile_pic : noImage} className='w-18 h-18 rounded-full object-cover' alt="" />
                            </div>
                            <div className='my-4'>
                                <p className='text-3xl font-semibold pb-1'>{userDetails.first_name || ""} {userDetails.last_name || ""}</p>
                                <p className='text-lg font-medium text-gray-500'>Are you sure you want to Accept?</p>
                            </div>
                            <div className='flex justify-between mt-8 mb-2 px-4'>
                                <button onClick={() => { setModalOpens(false) }} className='bg-[#7424F2] text-white w-30 text-sm rounded-full py-3 font-semibold text-base mx-3'>Cancel</button>
                                <button onClick={() => handleAccept(1)} className='bg-[#36AB0E] rounded-full w-30 text-sm py-3 text-white font-semibold text-base mx-3'>
                                    {
                                        isLoding ?
                                            <div className='flex justify-center'>
                                                <ThreeCircles
                                                    width={20}
                                                    height={20}
                                                    color="#fff"
                                                    ariaLabel="circles-loading"
                                                    wrapperStyle={{}}
                                                    visible={true}
                                                    wrapperClass=""
                                                />
                                            </div>
                                            : "Yes, Accept"
                                    }

                                </button>
                            </div>
                        </div>
                    </Dialog.Description>
                </Dialog.Panel>
            </Dialog>

            <Dialog
                open={isSkillAccept}
                onClose={() => setIsSkillAccept(false)}
                size="md"
            >
                <Dialog.Panel>
                    <Dialog.Description className="grid grid-cols-12">
                        <div className='col-span-12 sm:col-span-12 text-center fontNew lg:px-12 px-7 pb-3'>
                            <div className='my-4'>
                                <p className='text-2xl font-semibold pb-3'>{skillDetails.service_name || ""}</p>
                                <p className='text-lg font-medium text-gray-500'>Are you sure you want to skill Accept?</p>
                            </div>
                            <div className='flex justify-between mt-8 mb-2 px-4'>
                                <button onClick={() => { setIsSkillAccept(false) }} className='bg-[#7424F2] text-white w-30 text-sm rounded-full py-3 font-semibold text-base mx-3'>Cancel</button>
                                <button onClick={() => handleSkills(1)} className='bg-[#36AB0E] rounded-full w-30 text-sm py-3 text-white font-semibold text-base mx-3'>
                                    {
                                        isLoding ?
                                            <div className='flex justify-center'>
                                                <ThreeCircles
                                                    width={20}
                                                    height={20}
                                                    color="#fff"
                                                    ariaLabel="circles-loading"
                                                    wrapperStyle={{}}
                                                    visible={true}
                                                    wrapperClass=""
                                                />
                                            </div>
                                            : "Yes, Accept"
                                    }

                                </button>
                            </div>
                        </div>
                    </Dialog.Description>
                </Dialog.Panel>
            </Dialog>

            <Dialog
                open={isModalOpen}
                onClose={() => setModalOpen(false)}
                size="md"
            >
                <Dialog.Panel>
                    <Dialog.Description className="grid grid-cols-12">
                        <div className='col-span-12 sm:col-span-12 text-center fontNew lg:px-12 px-7 pb-3'>
                            <div className='flex justify-center my-3'>
                                <img src={userDetails.profile_pic ? IMG_URL + userDetails.profile_pic : noImage} className='w-18 h-18 rounded-full object-cover' alt="" />
                            </div>
                            <div className='my-4'>
                                <p className='text-3xl font-semibold pb-1'>{userDetails.first_name || ""} {userDetails.last_name || ""}</p>
                                <p className='text-lg font-medium text-gray-500'>Are you sure you want to Reject?</p>
                            </div>
                            <form onSubmit={(e) => { e.preventDefault(); handleAccept(2) }}>
                                <div>
                                    <textarea name="" onChange={(e) => setRejectData(e.target.value)} value={rejectData} required className='p-3 border-2 rounded-md outline-none' placeholder='Enter reject profile reason' cols="30" rows="5"></textarea>
                                </div>
                                <div className='flex justify-between mt-8 mb-2 px-4'>
                                    <button type='button' onClick={() => { setModalOpen(false) }} className='bg-[#7424F2] text-white w-30 text-sm rounded-full py-3 font-semibold text-base mx-3'>Cancel</button>
                                    <button type='submit' className='bg-[#FFD6D6] rounded-full w-30 text-sm py-3 text-[#E42323] font-semibold text-base mx-3'>
                                        {
                                            isLoding ?
                                                <div className='flex justify-center'>
                                                    <ThreeCircles
                                                        width={20}
                                                        height={20}
                                                        color="#E42323"
                                                        ariaLabel="circles-loading"
                                                        wrapperStyle={{}}
                                                        visible={true}
                                                        wrapperClass=""
                                                    />
                                                </div>
                                                : "Yes, Reject"
                                        }
                                    </button>
                                </div>
                            </form>
                        </div>
                    </Dialog.Description>
                </Dialog.Panel>
            </Dialog>

            <Dialog
                open={isSkillReject}
                onClose={() => setIsSkillReject(false)}
                size="md"
            >
                <Dialog.Panel>
                    <Dialog.Description className="grid grid-cols-12">
                        <div className='col-span-12 sm:col-span-12 text-center fontNew lg:px-12 px-7 pb-3'>
                            <div className='my-4'>
                                <p className='text-2xl font-semibold pb-3'>{skillDetails.service_name || ""}</p>
                                <p className='text-lg font-medium text-gray-500'>Are you sure you want to skill <br /> Reject?</p>
                            </div>
                            <div className='flex justify-between mt-8 mb-2 px-4'>
                                <button onClick={() => { setIsSkillReject(false) }} className='bg-[#7424F2] text-white w-30 text-sm rounded-full py-3 font-semibold text-base mx-3'>Cancel</button>
                                <button onClick={() => handleSkills(2)} className='bg-[#FFD6D6] rounded-full w-30 text-sm py-3 text-[#E42323] font-semibold text-base mx-3'>
                                    {
                                        isLoding ?
                                            <div className='flex justify-center'>
                                                <ThreeCircles
                                                    width={20}
                                                    height={20}
                                                    color="#E42323"
                                                    ariaLabel="circles-loading"
                                                    wrapperStyle={{}}
                                                    visible={true}
                                                    wrapperClass=""
                                                />
                                            </div>
                                            : "Yes, Reject"
                                    }
                                </button>
                            </div>
                        </div>
                    </Dialog.Description>
                </Dialog.Panel>
            </Dialog>

            <Dialog
                open={isTerminated}
                onClose={() => setIsTerminated(false)}
                size="md"
            >
                <Dialog.Panel>
                    <Dialog.Description className="grid grid-cols-12">
                        <div className='col-span-12 sm:col-span-12 text-center fontNew lg:px-12 px-7 pb-3'>
                            <div className='flex justify-center my-3'>
                                <img src={userDetails.profile_pic ? IMG_URL + userDetails.profile_pic : noImage} className='w-18 h-18 rounded-full object-cover' alt="" />
                            </div>
                            <div className='my-4'>
                                <p className='text-3xl font-semibold pb-1'>{userDetails.first_name || ""} {userDetails.last_name || ""}</p>
                                <p className='text-lg font-medium text-gray-500'>Are you sure you want to Reject?</p>
                            </div>
                            <div className='flex justify-between mt-8 mb-2 px-4'>
                                <button onClick={() => { setIsTerminated(false) }} className='bg-[#7424F2] text-white w-32 text-sm rounded-full py-3 font-semibold text-base mx-2'>Cancel</button>
                                <button onClick={() => handleAccept(3)} className='bg-[#FFD6D6] rounded-full w-32 text-sm py-3 text-[#E42323] font-semibold text-base mx-2'>
                                    {
                                        isLoding ?
                                            <div className='flex justify-center'>
                                                <ThreeCircles
                                                    width={20}
                                                    height={20}
                                                    color="#E42323"
                                                    ariaLabel="circles-loading"
                                                    wrapperStyle={{}}
                                                    visible={true}
                                                    wrapperClass=""
                                                />
                                            </div>
                                            : "Yes, Terminate"
                                    }
                                </button>
                            </div>
                        </div>
                    </Dialog.Description>
                </Dialog.Panel>
            </Dialog>

            <Dialog
                open={isFormModal}
                onClose={() => setIsFormModal(false)}
                size="md"
            >
                <Dialog.Panel>
                    <Dialog.Description className="grid grid-cols-12">
                        <div className='col-span-12 sm:col-span-12 text-center fontNew px-3 pb-3'>
                            <div className='my-4'>
                                <p className='text-3xl font-semibold pb-1'>W-9 From</p>
                            </div>
                            <div>
                                <div className='flex justify-between py-2'>
                                    <p className='font-medium text-gray-500 text-sm'>Country :</p>
                                    <p className='font-bold text-sm'>{userDetails.country || "-"}</p>
                                </div>
                                <div className='flex justify-between py-2'>
                                    <p className='font-medium text-gray-500 text-sm'>Individual/sole proprietor or single :</p>
                                    <p className='font-bold text-sm'>{userDetails.individual || "-"}</p>
                                </div>
                                <div className='flex justify-between py-2'>
                                    <p className='font-medium text-gray-500 text-sm'>Trade name (DBA) :</p>
                                    <p className='font-bold text-sm'>{userDetails.trade_name || "-"}</p>
                                </div>
                                <div className='flex justify-between py-2'>
                                    <p className='font-medium text-gray-500 text-sm'>EIN :</p>
                                    <p className='font-bold text-sm'>{userDetails.ein || "-"}</p>
                                </div>
                                <div className='flex justify-between py-2'>
                                    <p className='font-medium text-gray-500 text-sm'>EIN Number :</p>
                                    <p className='font-bold text-sm'>{userDetails.ein_number || "-"}</p>
                                </div>
                                <div className='flex justify-between py-2'>
                                    <p className='font-medium text-gray-500 text-sm'>Are you subject to backup withholding? :</p>
                                    <p className='font-bold text-sm'>
                                        {userDetails.backup_with_holding == 0 ?
                                            "Yes"
                                            : userDetails.backup_with_holding == 1 ?
                                                "No"
                                                : "-"
                                        }
                                    </p>
                                </div>
                            </div>
                            <div className=' my-3'>
                                <p className='font-bold text-lg my-2'>Sign Image</p>
                                {
                                    userDetails.sign_image ?
                                        <img src={IMG_URL + userDetails.sign_image} className='w-full rounded-xl object-cover' alt="" />
                                        : "No Image Found"
                                }
                            </div>
                        </div>
                    </Dialog.Description>
                </Dialog.Panel>
            </Dialog>

            <Dialog
                open={isDelete}
                onClose={() => setIsDelete(false)}
                size="md"
            >
                <Dialog.Panel>
                    <Dialog.Description className="grid grid-cols-12">
                        <div className='col-span-12 sm:col-span-12 text-center fontNew lg:px-12 px-7 pb-3'>
                            <div className='flex justify-center my-3'>
                                <img src={userDetails.profile_pic ? IMG_URL + userDetails.profile_pic : noImage} className='w-18 h-18 rounded-full object-cover' alt="" />
                            </div>
                            <div className='my-4'>
                                <p className='text-2xl font-semibold pb-1'>{userDetails.first_name || ""} {userDetails.last_name || ""}</p>
                                <p className='text-lg font-medium text-gray-500'>Are you sure you want to delete this user?</p>
                            </div>
                            <div className='flex justify-between mt-8 mb-2 px-4'>
                                <button onClick={() => { setIsDelete(false) }} className='bg-[#7424F2] text-white w-30 text-sm rounded-full py-3 font-semibold text-base mx-3'>Cancel</button>
                                <button onClick={() => handleDelete()} className='bg-[#FFD6D6] rounded-full w-30 text-sm py-3 text-[#E42323] font-semibold text-base mx-3'>
                                    {
                                        isLoding ?
                                            <div className='flex justify-center'>
                                                <ThreeCircles
                                                    width={20}
                                                    height={20}
                                                    color="#E42323"
                                                    ariaLabel="circles-loading"
                                                    wrapperStyle={{}}
                                                    visible={true}
                                                    wrapperClass=""
                                                />
                                            </div>
                                            : "Yes, Delete"
                                    }

                                </button>
                            </div>
                        </div>
                    </Dialog.Description>
                </Dialog.Panel>
            </Dialog>
        </div>
    )
}

export default Professional