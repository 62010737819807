import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import noImage from "../../Assets/Image/noImage.png";
import dash from "../../Assets/Image/dash.png";
import message from "../../Assets/Image/message.png";
import briefcase from "../../Assets/Image/briefcase.png";
import user from "../../Assets/Image/user.png";
import moneys from "../../Assets/Image/moneys.png";
import calendar from "../../Assets/Image/calendar.png";
import setting from "../../Assets/Image/setting.png";
import out from "../../Assets/Image/logout.png";
import posts from "../../Assets/Image/sticky-notes.png";
import Dialog from "../../Base-Component/Dialog/Dialog";
import { IMG_URL } from "../../Services/Api";

const SideMenu = ({ sidebarOpen, setSidebarOpen }) => {
  const location = useLocation();
  const navigate = useNavigate()

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const [isModalOpens, setModalOpens] = useState(false);

  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
  );

  const [adminData, setAdminData] = useState({})

  useEffect(() => {
    const admin = JSON.parse(localStorage.getItem("Admin"))

    if (admin) {
      setAdminData(admin)
    }
  }, [])

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded.toString());
    if (sidebarExpanded) {
      document.querySelector("body")?.classList.add("sidebar-expanded");
    } else {
      document.querySelector("body")?.classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  const handleLogout = () => {
    localStorage.clear()
    navigate("/")
  }

  return (
    <>
      <aside
        ref={sidebar}
        className={`absolute left-0 top-0 z-9 flex h-screen w-72.5 flex-col overflow-y-hidden duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0 ${sidebarOpen ? "translate-x-0" : "-translate-x-full"
          }`}
        style={{ backgroundColor: "#fff" }}
      >
        {/* <!-- SIDEBAR HEADER --> */}
        <div className="flex items-center justify-between ">
          <div className='flex items-center py-3 px-7' style={{ backgroundColor: "#e9e7e7" }}>
            <img src={adminData.profile_pic ? IMG_URL + adminData.profile_pic : noImage} className='w-14 h-14 rounded-full' alt="" />
            <div className='ps-2'>
              <p className='font-bold text-base'>{adminData.first_name || "Xtrakare"} {adminData.last_name || ""}</p>
              <p className='font-medium text-gray-500 text-sm'>{adminData.email_id || ""}</p>
            </div>
          </div>
          <button
            ref={trigger}
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
            className="block lg:hidden"
          >
            <svg
              className="fill-current"
              width="20"
              height="18"
              viewBox="0 0 20 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z"
                fill=""
              />
            </svg>
          </button>
        </div>
        {/* <!-- SIDEBAR HEADER --> */}

        <div className="no-scrollbar flex flex-col justify-between overflow-y-auto duration-300 ease-linear h-full px-10 py-5">
          <div className="flex justify-start">
            <div>
              <p className="py-2 fontNew text-gray-500 font-medium">General</p>
              <Link to={"/dashboard"} onClick={() => setSidebarOpen(!sidebarOpen)} className={location.pathname == "/dashboard" ? "opacity-100" : "opacity-60"}>
                <span className="flex items-center py-4">
                  <img src={dash} className="w-7" alt="" />
                  <p className=" ms-3 fontNew font-medium">Dashboard</p>
                </span>
              </Link>
              {/* <Link to={"/messages"} onClick={() => setSidebarOpen(!sidebarOpen)} className={location.pathname == "/messages" ? "opacity-100" : "opacity-60"}>
                <span className="flex items-center py-4">
                  <img src={message} className="w-7" alt="" />
                  <p className=" ms-3 fontNew font-medium">Messages</p>
                </span>
              </Link> */}
              <Link to={"/professional"} onClick={() => setSidebarOpen(!sidebarOpen)} className={location.pathname == "/professional" ? "opacity-100" : "opacity-60"}>
                <span className="flex items-center py-4">
                  <img src={briefcase} className="w-7" alt="" />
                  <p className=" ms-3 fontNew font-medium">Professional</p>
                </span>
              </Link>
              <Link to={"/users"} onClick={() => setSidebarOpen(!sidebarOpen)} className={location.pathname == "/users" ? "opacity-100" : "opacity-60"}>
                <span className="flex items-center py-4">
                  <img src={user} className="w-7" alt="" />
                  <p className=" ms-3 fontNew font-medium">Users</p>
                </span>
              </Link>
              <Link to={"/earnings"} onClick={() => setSidebarOpen(!sidebarOpen)} className={location.pathname == "/earnings" ? "opacity-100" : "opacity-60"}>
                <span className="flex items-center py-4">
                  <img src={moneys} className="w-7" alt="" />
                  <p className=" ms-3 fontNew font-medium">Earnings</p>
                </span>
              </Link>
              <Link to={"/shifts"} onClick={() => setSidebarOpen(!sidebarOpen)} className={location.pathname == "/shifts" ? "opacity-100" : "opacity-60"}>
                <span className="flex items-center py-4">
                  <img src={calendar} className="w-7" alt="" />
                  <p className=" ms-3 fontNew font-medium">Shifts</p>
                </span>
              </Link>
              <Link to={"/setting"} onClick={() => setSidebarOpen(!sidebarOpen)} className={location.pathname == "/setting" ? "opacity-100" : "opacity-60"}>
                <span className="flex items-center py-4">
                  <img src={setting} className="w-7" alt="" />
                  <p className=" ms-3 fontNew font-medium">Settings</p>
                </span>
              </Link>
              <Link to={"/addpost"} onClick={() => setSidebarOpen(!sidebarOpen)} className={location.pathname == "/addpost" ? "opacity-100" : "opacity-60"}>
                <span className="flex items-center py-4">
                  <img src={posts} className="w-6" alt="" />
                  <p className=" ms-3 fontNew font-medium">Add Post</p>
                </span>
              </Link>
            </div>
          </div>
          <div className="flex items-center pb-7 cursor-pointer" onClick={() => { setModalOpens(true) }}>
            <img src={out} className="w-7" alt="" />
            <p className=" ms-3 fontNew font-medium text-rose-600">Log out</p>
          </div>
        </div>
      </aside>

      <Dialog
        open={isModalOpens}
        onClose={() => setModalOpens(false)}
        size="md"
      >
        <Dialog.Panel>
          <Dialog.Description className="grid grid-cols-12">
            <div className='col-span-12 sm:col-span-12 text-center fontNew lg:px-12 px-5 pb-3'>
              <div className='flex justify-center my-3'>
                <img src={out} className='w-16' alt="" />
              </div>
              <div className='my-4'>
                <p className='text-3xl font-bold pb-1'>Log Out?</p>
                <p className='text-lg font-medium text-gray-500 py-2'>are you sure you want to proceed with logging out?</p>
              </div>
              <div className='flex justify-between mt-8 mb-2 px-4'>
                <button onClick={handleLogout} className='bg-[#FFD6D6] text-[#E42323] w-36 text-sm rounded-full py-3 font-semibold text-base mx-3'>Yes, Log Out</button>
                <button onClick={() => { setModalOpens(false) }} className='bg-[#7424F2] rounded-full w-36 text-sm py-3 text-white font-semibold text-base mx-3'>Cancel</button>
              </div>
            </div>
          </Dialog.Description>
        </Dialog.Panel>
      </Dialog>
    </>

  );
};

export default SideMenu;
