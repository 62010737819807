import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Login from '../Page/Login/Login'
import ForgotPassword from '../Page/ForgotPassword/ForgotPassword'
import Verification from '../Page/Verification/Verification'
import ResetPassword from '../Page/ResetPassword/ResetPassword'
import Dashboard from '../Page/Dashboard/Dashboard'
import MainLayout from "../Layouts/MainLayout/MainLayout"
import Messages from '../Page/Messages/Messages'
import Professional from '../Page/Professional/Professional'
import Users from '../Page/Users/Users'
import Earnings from '../Page/Earnings/Earnings'
import Shifts from '../Page/Shifts/Shifts'
import Setting from '../Page/Setting/Setting'
import DeleteAccount from '../Page/DeleteAccount/DeleteAccount'
import AddPost from '../Page/AddPost/AddPost'

function RouterData() {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path='*' element={<Login />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/forgotpassword' element={<ForgotPassword />} />
                    <Route path='/verification' element={<Verification />} />
                    <Route path='/resetpassword' element={<ResetPassword />} />
                    <Route path='/deleteaccount' element={<DeleteAccount />} />
                    <Route path='/dashboard' element={<MainLayout><Dashboard /></MainLayout>} />
                    <Route path='/messages' element={<MainLayout><Messages /></MainLayout>} />
                    <Route path='/professional' element={<MainLayout><Professional /></MainLayout>} />
                    <Route path='/users' element={<MainLayout><Users /></MainLayout>} />
                    <Route path='/earnings' element={<MainLayout><Earnings /></MainLayout>} />
                    <Route path='/shifts' element={<MainLayout><Shifts /></MainLayout>} />
                    <Route path='/setting' element={<MainLayout><Setting /></MainLayout>} />
                    <Route path='/addpost' element={<MainLayout><AddPost /></MainLayout>} />
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default RouterData