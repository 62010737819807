import React, { useState, useEffect } from 'react'
import FormSelect from '../../Base-Component/FormSelect/FormSelect'
import wallet from '../../Assets/Image/wallet-add.png'
import coin from '../../Assets/Image/coin.png'
import moneysend from '../../Assets/Image/money-send.png'
import moneyss from '../../Assets/Image/moneyss.png'
import leftbtn from '../../Assets/Image/left btn.png'
import rightbtn from '../../Assets/Image/right btn.png'
import Dialog from '../../Base-Component/Dialog/Dialog'
import ReactApexChart from 'react-apexcharts';
import { adminByPayment, adminMenuallyByPayment, clockOutShift, getPaymentList, sendnotificationData } from '../../Services/ApiServices'
import moment from 'moment'
import { EARNING_DASHBOARD_API, GET_EDIT_MY_PROFILE, GRAPH_DATA_API, IMG_URL } from '../../Services/Api'
import { Bars, ThreeCircles } from 'react-loader-spinner'
import xbtn from '../../Assets/svg/x button.svg'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'


function Earnings() {

    const navigate = useNavigate()
    const tokens = localStorage.getItem("UToken")

    const [isModalOpens, setModalOpens] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [isClockOut, setIsClockOut] = useState(false);
    const [isLoding, setIsLoding] = useState(false)
    const [isLoader, setIsLoader] = useState(false)
    const [isLoad, setIsLoad] = useState(false)

    const [page, setPage] = useState(1)
    const [pageNo, setPageNo] = useState(1)
    const [totalPage, setTotalPage] = useState()
    const [totalCount, setTotalCount] = useState()
    const [isUser, setIsUser] = useState()

    const [userDescription, setUserDescription] = useState("")
    const [userId, setUserId] = useState("")
    const [serviceId, setServiceId] = useState("")
    const [serviceFee, setServiceFee] = useState("")

    const [data, setData] = useState({})
    const [proDetails, setProDetails] = useState({})
    const [earningCount, setEarningCount] = useState({})

    const [userData, setUserData] = useState([])
    const [graphData, setGraphData] = useState([])
    const [payments, setPayments] = useState([])
    const [earnings, setEarnings] = useState([])

    useEffect(() => {
        if (!tokens) {
            navigate("/login")
        }
        handleEditProfile()
        handleDashboard()
        handleGraph()
    }, [])

    useEffect(() => {
        handleShift()
    }, [pageNo])

    const handleGraph = () => {
        axios.post(GRAPH_DATA_API, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((res) => {
                const status = res.data.Status;
                const data = res.data.info;
                if (status == 1) {
                    for (let i = 0; i < data.length; i++) {
                        const pay = data.map(item => item.payments);
                        const ern = data.map(item => item.earnings)

                        setPayments(pay)
                        setEarnings(ern)
                    }
                } else {
                    toast.error(res.data.Message)
                }
            })
            .catch((err) => {
                console.log("err >>", err)
            })
    }

    const series = [
        {
            name: 'Payments',
            type: 'column',
            data: payments,
            color: '#1456FF'
        },
        {
            name: 'Earnings',
            type: 'column',
            data: earnings,
            color: '#FF8A00'
        }
    ];

    const options = {
        chart: {
            height: 400,
            type: 'line',
            stacked: false
        },
        xaxis: {
            categories: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
        },
        yaxis: {
            min: 100
        },
        stroke: {
            width: [1, 1, 4]
        },
        tooltip: {
            fixed: {
                enabled: true,
                position: 'topLeft',
                offsetY: 30,
                offsetX: 60
            }
        }
    };

    const handleDashboard = () => {
        axios.post(EARNING_DASHBOARD_API, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((res) => {
                const status = res.data.Status;
                const data = res.data.info;
                if (status == 1) {
                    setEarningCount(data)
                } else {
                    console.log("error >>", res.data.Message)
                }
            })
            .catch((err) => {
                console.log("err >>", err)
            })
    }

    const renderPaginationButton = () => {
        const maxPagesToShow = 3;
        const buttons = [];
        const startPage = Math.max(1, page - Math.floor(maxPagesToShow / 2));
        const endPage = Math.min(totalCount, startPage + maxPagesToShow - 1);

        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <button
                    key={i}
                    onClick={() => handlePageChanges(i)}
                    className={i === page ? 'px-3 py-1 mx-1 bg-black rounded-full text-white' : 'px-3 py-1 mx-1'}
                >
                    {i}
                </button>
            );
        }
        return buttons;
    };

    const handlePageChanges = (page) => {
        setPage(page);
    };

    const handleShift = () => {
        setIsLoader(true)
        let param = {
            page_no: pageNo,
        }
        getPaymentList(param)
            .then((res) => {
                const status = res.data.Status;
                const data = res.data.info;
                if (status == 1) {
                    setUserData(data)
                    setTotalPage(res.data.total_page)
                } else {
                    console.log("error >>", res.data.Message);
                }
                setIsLoader(false)
            })
            .catch((err) => {
                console.log("err >>", err);
                setIsLoader(false)
            })
    }

    const renderPaginationButtonss = () => {
        const maxPagesToShow = 3;
        const buttons = [];
        const startPage = Math.max(1, pageNo - Math.floor(maxPagesToShow / 2));
        const endPage = Math.min(totalPage, startPage + maxPagesToShow - 1);

        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <button
                    key={i}
                    onClick={() => handlePageChange(i)}
                    className={i === pageNo ? 'px-3 py-1 mx-1 bg-black rounded-full text-white' : 'px-3 py-1 mx-1'}
                >
                    {i}
                </button>
            );
        }
        return buttons;
    };

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const handlePayment = () => {
        setIsLoad(true)
        let param = {
            payment_intent_id: data.payment_id,
            transaction_to: data.accept_by,
            sub_amount: data.sub_amount,
            payment_id: data.payment_id,
            post_service_id: data.post_service_id,
            post_id: data.post_id
        }
        adminByPayment(param)
            .then((res) => {
                const status = res.data.Status;
                if (status == 1) {
                    toast.success(res.data.Message)
                    setModalOpens(false)
                    handleShift()
                } else {
                    console.log("error >>", res.data.Message);
                    toast.error(res.data.Message)
                }
                setIsLoad(false)
            })
            .catch((err) => {
                console.log("err >>", err);
                setIsLoad(false)
            })
    }

    const handleMenuallyPayment = () => {
        setIsLoad(true)
        let param = {
            transaction_to: data.accept_by,
            sub_amount: data.sub_amount,
            post_service_id: data.post_service_id,
            post_id: data.post_id
        }
        adminMenuallyByPayment(param)
            .then((res) => {
                const status = res.data.Status;
                if (status == 1) {
                    toast.success(res.data.Message)
                    setModalOpens(false)
                    handleShift()
                } else {
                    console.log("error >>", res.data.Message);
                    toast.error(res.data.Message)
                }
                setIsLoad(false)
            })
            .catch((err) => {
                console.log("err >>", err);
                setIsLoad(false)
            })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setIsLoad(true)
        let param = {
            user_id: userId,
            text: userDescription,
            post_service_id: serviceId
        }
        sendnotificationData(param)
            .then((res) => {
                const status = res.data.Status;
                if (status == 1) {
                    setModalOpen(false)
                    toast.success(res.data.Message)
                    setUserDescription("")
                } else {
                    console.log("error >>", res.data.Message);
                    toast.error(res.data.Message)
                }
                setIsLoad(false)
            })
            .catch((err) => {
                console.log("err >>", err);
                setIsLoad(false)
            })
    }

    const handleEditProfile = () => {
        axios.post(GET_EDIT_MY_PROFILE)
            .then((res) => {
                const status = res.data.Status;
                const data = res.data.info;
                if (status == 1) {
                    setServiceFee(data.service_fee_percent)
                } else {
                    console.log("error >>", res.data.Message);
                }
            })
            .catch((err) => {
                console.log("err >>", err);
            })
    }

    const handleClockout = (e) => {
        e.preventDefault()
        setIsLoad(true)

        const postStartTime = new Date(data.post_start_time);
        const postEndTime = new Date(data.post_end_time);
        const differenceInMilliseconds = postEndTime.getTime() - postStartTime.getTime();
        const differenceInHours = (differenceInMilliseconds / (1000 * 3600)).toFixed(2);
        const hours = differenceInHours;

        const subAmount = hours * data.service_hourly_price;
        const serFee = subAmount * (serviceFee / 100);
        const totalAmount = (serFee + subAmount).toFixed(2);

        let param = {
            post_service_id: data.post_service_id,
            shift_length: hours,
            sub_amount: subAmount,
            total_amount: totalAmount,
            service_fee: serFee
        }

        clockOutShift(param)
            .then((res) => {
                const status = res.data.Status;
                if (status == 1) {
                    setIsClockOut(false)
                    handleShift()
                } else {
                    toast.error(res.data.Message)
                }
                setIsLoad(false)
            })
            .catch((err) => {
                console.log("err>>", err);
            })
    }

    return (

        <div className='grid grid-cols-12 gap-2'>
            <div className='col-span-12 lg:col-span-12 2xl:col-span-12'>
                <div className='py-4 px-6 bg-white rounded-xl'>
                    <div>
                        <div className='text-2xl font-semibold fontNew mx-2 my-3 flex justify-start'>
                            <p>Overview</p>
                        </div>
                        <div className='flex flex-wrap'>
                            <div className='md:w-64 w-full bg-[#36ab0e12] py-4 px-4 rounded-2xl fontNew hover:drop-shadow-lg duration-300 m-2 cursor-pointer'>
                                <div className='flex items-center justify-around'>
                                    <img src={coin} className='w-8' alt="" />
                                    <div>
                                        <p className='font-medium text-base my-2'>Earnings in {moment().format("MMM")}</p>
                                        <p className='text-2xl font-bold my-2'>$ {earningCount.monthly_earning || "0"}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='md:w-64 w-full bg-[#ff8a0012] py-4 px-4 rounded-2xl fontNew hover:drop-shadow-lg duration-300 m-2 cursor-pointer'>
                                <div className='flex items-center justify-around'>
                                    <img src={moneyss} className='w-8' alt="" />
                                    <div>
                                        <p className='font-medium text-base my-2'>Total Earnings</p>
                                        <p className='text-2xl font-bold my-2'>$ {earningCount.total_earning || "0"}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='py-3'>
                        <div className='text-2xl font-semibold fontNew mx-2 my-10 flex justify-between items-center'>
                            <p className='text-xl'>Earnings</p>
                            {/* <div>
                                <FormSelect id="category" name="fuel_type" className="select-arrow-hidden text-base font-medium w-40 py-2 rounded-lg outline-0 bg-[#d3d3d366]">
                                    <option label="All"></option>
                                    <option value="Petrol">Pending</option>
                                    <option value="Diesel">Ongoing</option>
                                    <option value="Hybrid">Completed</option>
                                    <option value="Electric">Cancel</option>
                                </FormSelect>
                            </div> */}
                        </div>
                        <div>
                            <div id="chart">
                                <ReactApexChart options={options} series={series} type="line" height={400} />
                            </div>
                            <div id="html-dist"></div>
                        </div>
                    </div>
                    <div className="py-3">
                        <div>
                            <div className='flex justify-between items-center fontNew my-5 mx-3'>
                                <p className='text-2xl font-semibold'>Pending Payments</p>
                            </div>
                            <div className='grid grid-cols-12 fontNew'>
                                <div className='col-span-12 overflow-auto rounded-2xl border-[1px]'>
                                    {
                                        isLoader ?
                                            <div className='h-[600px] w-full flex items-center justify-center'>
                                                <Bars
                                                    width={30}
                                                    height={30}
                                                    color="#000"
                                                    ariaLabel="circles-loading"
                                                    wrapperStyle={{}}
                                                    visible={true}
                                                    wrapperClass=""
                                                />
                                            </div>
                                            :
                                            <>
                                                {
                                                    userData == "" ?
                                                        <div className='h-[75vh] w-full flex items-center justify-center'>
                                                            <p className='text-center text-lg font-semibold text-gray-500'>Data Not Found</p>
                                                        </div>
                                                        :
                                                        <table className='w-full'>
                                                            <tr className='bg-gray-100 text-gray-500'>
                                                                <th className='py-4 px-5 text-base whitespace-nowrap text-start'>#</th>
                                                                <th className='py-4 px-3 text-base whitespace-nowrap text-start'>Professional</th>
                                                                <th className='py-4 px-3 text-base whitespace-nowrap text-start'>Role</th>
                                                                <th className='py-4 px-3 text-base whitespace-nowrap text-start'>Start Time</th>
                                                                <th className='py-4 px-3 text-base whitespace-nowrap text-start'>End Time</th>
                                                                <th className='py-4 px-3 text-base whitespace-nowrap text-start'>Working Hours</th>
                                                                <th className='py-4 px-3 text-base whitespace-nowrap text-start'>Pay Rate</th>
                                                                <th className='py-4 px-3 text-base whitespace-nowrap text-start'>Clock Out</th>
                                                                <th className='py-4 px-3 text-base whitespace-nowrap text-start'>Total</th>
                                                                <th className='py-4 px-3 text-base whitespace-nowrap text-start'>Status</th>
                                                                <th className='py-4 px-3 text-base whitespace-nowrap text-center'>Pay</th>
                                                                <th className='py-4 px-3 text-base whitespace-nowrap text-center'>Prof. Notification</th>
                                                                <th className='py-4 px-3 text-base whitespace-nowrap text-center'>User Notification</th>
                                                            </tr>
                                                            {
                                                                userData?.map((item, index) => {
                                                                    return (
                                                                        <tr className='bg-white text-sm font-semibold border-b border-zinc-100' key={index}>
                                                                            <td className='text-start p-4'>
                                                                                <div className='whitespace-nowrap'>
                                                                                    {item.post_service_id || "-"}
                                                                                </div>
                                                                            </td>
                                                                            <td className='text-start p-4'>
                                                                                <div className='whitespace-nowrap'>
                                                                                    {item.first_name || "-"} {item.last_name || "-"}
                                                                                </div>
                                                                            </td>
                                                                            <td className='text-start p-4'>
                                                                                <div className='whitespace-nowrap'>
                                                                                    {item.service_name || "-"}
                                                                                </div>
                                                                            </td>
                                                                            <td className='text-start p-4'>
                                                                                <div className='whitespace-nowrap'>
                                                                                    {item.clock_in == null ? moment(item.post_start_time).format("LT") : moment(item.clock_in).format("LT")}
                                                                                </div>
                                                                            </td>
                                                                            <td className='text-start p-4'>
                                                                                <div className='whitespace-nowrap'>
                                                                                    {item.clock_out == null ? moment(item.post_end_time).format("LT") : moment(item.clock_out).format("LT")}
                                                                                </div>
                                                                            </td>
                                                                            <td className='text-start p-4'>
                                                                                <div className='whitespace-nowrap text-center'>
                                                                                    {item.shift_length || "-"}
                                                                                </div>
                                                                            </td>
                                                                            <td className='text-start p-4'>
                                                                                <div className='whitespace-nowrap'>
                                                                                    ${item.service_hourly_price || "0"}/hr
                                                                                </div>
                                                                            </td>
                                                                            <td className='text-start p-4'>
                                                                                <div className='whitespace-nowrap text-center'>
                                                                                    {
                                                                                        item.post_services_status == 1 && item.clock_in != null ?
                                                                                            <button onClick={() => { setData(item); setIsClockOut(true) }} className='bg-[#2336E41A] py-1 text-xs px-3 text-[#2336E4] border-[#2336E4] border-[1px] rounded-lg'>Clock Out</button>
                                                                                            : "-"
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                            <td className='text-start p-4 text-green-600'>
                                                                                <div className='whitespace-nowrap'>
                                                                                    ${item.sub_amount || "0"}
                                                                                </div>
                                                                            </td>
                                                                            <td className='text-start p-4'>
                                                                                <div className='whitespace-nowrap'>
                                                                                    {
                                                                                        item.post_services_status == 1 && item.clock_in != null ?
                                                                                            <p className='text-[#7424F2]'>Clock In</p>
                                                                                            :
                                                                                            item.post_services_status == 1 && item.clock_out != null ?
                                                                                                <p className='text-[#2336E4]'>Clock Out</p>
                                                                                                :
                                                                                                item.post_services_status == 2 && item.clock_out != null ?
                                                                                                    <p className='text-[#E42323]'>Waiting user pay</p>
                                                                                                    :
                                                                                                    item.post_services_status == 2 && item.pay_manually == 1 ?
                                                                                                        <p className='text-[#F2994A]'>Pay by Admin</p>
                                                                                                        :
                                                                                                        <p className='text-[#038A00]'>Completed</p>
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                            <td className='text-center p-4'>
                                                                                {
                                                                                    item.post_services_status == 1 ?
                                                                                        "-" :
                                                                                        item.post_services_status == 3 ?
                                                                                            <button onClick={() => { setModalOpens(true); setData(item); setProDetails(item) }} className='bg-white rounded-full border-2 whitespace-nowrap border-[#36AB0E] text-sm py-1 px-4 text-[#36AB0E] font-medium text-base mx-3'>Pay Now</button>
                                                                                            : item.post_services_status == 7 ?
                                                                                                <button className='bg-white rounded-full text-base py-1 px-4 text-[#000] font-medium text-base mx-3'>Paid</button>
                                                                                                :
                                                                                                <button onClick={() => { setModalOpens(true); setData(item); setProDetails(item) }} className='bg-white rounded-full border-2 whitespace-nowrap border-[#36AB0E] text-sm py-1 px-4 text-[#36AB0E] font-medium text-base mx-3'>Pay Now</button>
                                                                                }
                                                                            </td>
                                                                            <td className='text-center p-4'>
                                                                                <button onClick={() => { setModalOpen(true); setIsUser(0); setUserId(item.accept_by); setServiceId(item.post_service_id); setUserDescription(""); setProDetails(item) }} className='bg-white rounded-full border-2 border-violet-700 text-sm py-1 px-4 text-violet-700 font-medium text-base mx-3'>Professional</button>
                                                                            </td>
                                                                            <td className='text-center p-4'>
                                                                                {
                                                                                    item.user_id == 1 ? "Admin"
                                                                                        :
                                                                                        <button onClick={() => { setModalOpen(true); setIsUser(1); setUserId(item.user_id); setServiceId(item.post_service_id); setUserDescription(""); setProDetails(item) }} className='bg-white rounded-full border-2 border-sky-500 text-sm py-1 px-4 text-sky-500 font-medium text-base mx-3'>User</button>
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </table>
                                                }
                                            </>
                                    }
                                </div>
                                {
                                    userData == "" ? ""
                                        :
                                        <div className='col-span-12 flex items-center justify-end lg:mx-10 px-3 py-4'>
                                            <div className='flex items-center cursor-pointer'>
                                                <button onClick={() => handlePageChange(pageNo - 1)} disabled={pageNo === 1}>
                                                    <img src={leftbtn} className='w-9 mx-3' alt="" />
                                                </button>
                                                <div className="flex items-center">
                                                    {renderPaginationButtonss()}
                                                </div>
                                                <button onClick={() => handlePageChange(pageNo + 1)} disabled={pageNo === totalPage}>
                                                    <img src={rightbtn} className='w-9 mx-3' alt="" />
                                                </button>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Dialog
                open={isModalOpen}
                onClose={() => setModalOpen(false)}
                size="md"
            >
                <Dialog.Panel>
                    <Dialog.Description className="grid grid-cols-12">
                        <div className='col-span-12 sm:col-span-12 text-center fontNew px-3 pb-3'>
                            <div onClick={() => { setModalOpen(false) }} className='flex justify-end mt-2 cursor-pointer'>
                                <img src={xbtn} className='w-8' alt="" />
                            </div>
                            <div>
                                {
                                    isUser == 0 ?
                                        <p className='text-lg font-semibold'>{proDetails.first_name || "-"} {proDetails.last_name || "-"}</p>
                                        :
                                        <p className='text-lg font-semibold'>{proDetails.user_first_name || "-"} {proDetails.user_last_name || "-"}</p>
                                }
                            </div>
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <textarea type="number" required rows="5" value={userDescription} max={100} min={0} onChange={(e) => setUserDescription(e.target.value)} className='bg-zinc-100 flex w-full p-2 mt-6 rounded-2xl border-2 border-slate-300 outline-none text-lg fontNew' placeholder='enter send message' />

                                <button type='submit' className='fontNew py-3 px-6 mt-10 w-40 rounded-2xl text-white font-medium text-base bg-[#7424F2]'>
                                    {
                                        isLoad ?
                                            <div className='flex justify-center items-center'>
                                                <ThreeCircles
                                                    width={24}
                                                    height={24}
                                                    color="#fff"
                                                    ariaLabel="circles-loading"
                                                    wrapperStyle={{}}
                                                    visible={true}
                                                    wrapperClass=""
                                                />
                                            </div>
                                            :
                                            "Send"
                                    }
                                </button>
                            </form>
                        </div>
                    </Dialog.Description>
                </Dialog.Panel>
            </Dialog>

            <Dialog
                open={isModalOpens}
                onClose={() => setModalOpens(false)}
                size="md"
            >
                <Dialog.Panel>
                    <Dialog.Description className="grid grid-cols-12">
                        <div className='col-span-12 sm:col-span-12 text-center fontNew lg:px-12 px-7 pb-3'>
                            <div className='flex justify-center my-3'>
                                <img src={coin} className='w-16' alt="" />
                            </div>
                            <div className='my-4'>
                                <p className='text-3xl font-bold pb-1'>Payment?</p>
                                <p className='text-lg font-medium text-gray-500'>Are you sure you want to pay {proDetails.first_name || ""} {proDetails.last_name || ""}?</p>
                            </div>
                            <div className='flex justify-between mt-8 mb-2 px-4'>
                                <button onClick={() => { setModalOpens(false) }} className='bg-[#FFD6D6] text-[#E42323] w-30 text-sm rounded-full py-3 font-semibold text-base mx-3'>Cancel</button>
                                {
                                    proDetails.post_services_status == 3 ?
                                        <button onClick={handlePayment} className='bg-[#7424F2] rounded-full w-30 text-sm py-3 text-white font-semibold text-base mx-3'>
                                            {
                                                isLoad ?
                                                    <div className='flex justify-center items-center'>
                                                        <ThreeCircles
                                                            width={20}
                                                            height={20}
                                                            color="#fff"
                                                            ariaLabel="circles-loading"
                                                            wrapperStyle={{}}
                                                            visible={true}
                                                            wrapperClass=""
                                                        />
                                                    </div>
                                                    :
                                                    "Yes, Pay"
                                            }
                                        </button>
                                        : <button onClick={handleMenuallyPayment} className='bg-[#7424F2] rounded-full w-30 text-sm py-3 text-white font-semibold text-base mx-3'>
                                            {
                                                isLoad ?
                                                    <div className='flex justify-center items-center'>
                                                        <ThreeCircles
                                                            width={20}
                                                            height={20}
                                                            color="#fff"
                                                            ariaLabel="circles-loading"
                                                            wrapperStyle={{}}
                                                            visible={true}
                                                            wrapperClass=""
                                                        />
                                                    </div>
                                                    :
                                                    "Yes, Manually"
                                            }
                                        </button>


                                }
                            </div>
                        </div>
                    </Dialog.Description>
                </Dialog.Panel>
            </Dialog>

            <Dialog
                open={isClockOut}
                onClose={() => setIsClockOut(false)}
                size="md"
            >
                <Dialog.Panel>
                    <Dialog.Description className="grid grid-cols-12">
                        <div className='col-span-12 sm:col-span-12 text-center fontNew px-3 pb-3'>
                            <div onClick={() => { setIsClockOut(false) }} className='flex justify-end mt-2 cursor-pointer'>
                                <img src={xbtn} className='w-8' alt="" />
                            </div>
                            <div className='my-4'>
                                <p className='text-3xl font-bold pb-1'>Shift</p>
                                <p className='text-lg font-medium text-gray-500'>Are you sure you want to Clock Out ?</p>
                            </div>

                            <button onClick={(e) => handleClockout(e)} className='fontNew py-3 px-6 mt-6 w-40 rounded-2xl text-white font-medium text-base bg-[#7424F2]'>
                                {
                                    isLoad ?
                                        <div className='flex justify-center items-center'>
                                            <ThreeCircles
                                                width={24}
                                                height={24}
                                                color="#fff"
                                                ariaLabel="circles-loading"
                                                wrapperStyle={{}}
                                                visible={true}
                                                wrapperClass=""
                                            />
                                        </div>
                                        :
                                        "Yes"
                                }
                            </button>
                        </div>
                    </Dialog.Description>
                </Dialog.Panel>
            </Dialog>
        </div>

    )
}

export default Earnings