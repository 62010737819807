import React, { useState, useEffect } from 'react'
import FormSelect from '../../Base-Component/FormSelect/FormSelect'
import human1 from '../../Assets/Image/Ellipse 1.png'
import human2 from '../../Assets/Image/Ellipse 7.png'
import human3 from '../../Assets/Image/Ellipse 8.png'
import human4 from '../../Assets/Image/Ellipse 9.png'
import human5 from '../../Assets/Image/Ellipse 5.png'
import human6 from '../../Assets/Image/Ellipse 6.png'
import circle from "../../Assets/Image/attach-circle.png"
import send from "../../Assets/Image/send-2.png"
import { useNavigate } from 'react-router-dom'

function Messages() {

    const navigate = useNavigate()
    const tokens = localStorage.getItem("UToken")

    useEffect(() => {
        if (!tokens) {
            navigate("/login")
        }
    }, [])


    const [notificationData, setNotificationData] = useState([
        {
            image: human1,
            name: "Rebbeca Doe",
            message: "Registered Nurse - Elder Care",
            count: 2
        },
        {
            image: human2,
            name: "Rebbeca Doe",
            message: "You: Your shift is about to start....",
            count: 5
        },
        {
            image: human1,
            name: "Rebbeca Doe",
            message: "Please give me today’s schedule repo...",
            count: 0
        },
        {
            image: human3,
            name: "Rebbeca Doe",
            message: "Hello There!",
            count: 3
        },
        {
            image: human4,
            name: "Rebbeca Doe",
            message: "You: Your shift is about to start....",
            count: 1
        },
        {
            image: human1,
            name: "Rebbeca Doe",
            message: "Please give me today’s schedule repo...",
            count: 0
        },
        {
            image: human4,
            name: "Rebbeca Doe",
            message: "Hello There!",
            count: 3
        },
        {
            image: human5,
            name: "Rebbeca Doe",
            message: "You: Your shift is about to start....",
            count: 1
        },
        {
            image: human6,
            name: "Rebbeca Doe",
            message: "Please give me today’s schedule repo...",
            count: 0
        },
    ])

    return (
        <>
            <p className="text-center font-medium text-red-500">Not Done Yet</p>
            <div className='grid grid-cols-12 gap-2'>
                <div className='col-span-12 lg:col-span-12 2xl:col-span-3'>
                    <div className='bg-white rounded-xl px-4 py-6 h-[90vh] overflow-x-hidden'>
                        <div className='flex justify-between fontNew'>
                            <p className='text-xl font-semibold'>Messages</p>
                            <div>
                                <FormSelect id="category" name="fuel_type" className="select-arrow-hidden text-base font-medium w-40 py-2 rounded-full outline-0 bg-[#d3d3d366]">
                                    <option value="Petrol">Professional</option>
                                    <option value="Diesel">User</option>
                                </FormSelect>
                            </div>
                        </div>
                        <div className='pt-7 pb-4'>
                            <input type="search" placeholder='Search messages....' className='w-full border-2 p-3 rounded-lg outline-0' />
                        </div>
                        <div>
                            {
                                notificationData.map((item, index) => {
                                    return (
                                        <div className="flex justify-between items-center fontNew py-4 border-b">
                                            <div className='flex items-center'>
                                                <img src={item.image} className="w-14" alt="" />
                                                <div className='ps-3'>
                                                    <p className='text-base font-bold'>{item.name}</p>
                                                    <p className='text-sm'>{item.message}</p>
                                                </div>
                                            </div>
                                            <div className='text-end flex items-center'>
                                                {
                                                    item.count != 0 ?
                                                        <p className="text-xs w-5 h-5 text-gray-500 bg-[#7424F2] font-medium rounded-full flex justify-center items-center text-white">{item.count}</p>
                                                        : ""
                                                }
                                                <div className='ml-2 opacity-50'>
                                                    <svg width="12" height="16" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path opacity="0.4" d="M2 1.5L7 6.5L2 11.5" stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>
                </div>
                <div className='col-span-12 lg:col-span-12 2xl:col-span-6'>
                    <div className='bg-white rounded-xl h-[90vh] fontNew'>
                        <div className='flex items-center px-6 py-4 border-b border-gray-200'>
                            <img src={human1} className="w-14" alt="" />
                            <div className='ps-3'>
                                <p className='text-lg font-bold'>Rebbeca Doe</p>
                                <p className='text-base'>Registered Nurse - Elder Care</p>
                            </div>
                        </div>
                        <div className='h-[70vh] p-3 overflow-x-hidden'>
                            <div className='flex justify-end text-white my-2'>
                                <div>
                                    <div className='bg-[#7424F2] px-5 py-2 rounded-tr-xl rounded-tl-3xl rounded-br-lg rounded-bl-3xl font-medium'>
                                        <p>Hello!</p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex justify-end text-white my-2'>
                                <div>
                                    <div className='bg-[#7424F2] px-5 py-2 rounded-tr-xl rounded-tl-3xl rounded-br-lg rounded-bl-3xl font-medium'>
                                        <p>How are you doing?</p>
                                    </div>
                                    <p className='text-gray-400 text-[13px] text-end pt-1'>10:45 AM</p>
                                </div>
                            </div>
                            <div className='flex justify-start text-[#5D5D5D] my-2'>
                                <div>
                                    <div className='bg-gray-200 px-5 py-2 rounded-tr-3xl rounded-tl-xl rounded-br-3xl rounded-bl-lg font-medium'>
                                        <p>How are you doing?</p>
                                    </div>
                                    <p className='text-gray-400 text-[13px] text-start pt-1'>10:45 AM</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='flex items-center p-2'>
                                <div className='bg-zinc-100 flex p-2 rounded-2xl border-2 border-slate-300 my-3 w-full m-1'>
                                    <img src={circle} className='p-2 w-11' alt="" />
                                    <input type="email" className='bg-transparent border-none outline-none w-full text-base fontNew' placeholder='Type your message here...' />
                                </div>
                                <button type='submit' className='m-1 flex items-center justify-center fontNew rounded-2xl w-40 py-5 text-white font-semibold text-base bg-[#7424F2]'>
                                    <p className='pr-2'>Send</p>
                                    <img src={send} className='h-6' alt="" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-span-12 lg:col-span-12 2xl:col-span-3'>
                    <p></p>
                </div>
            </div>
        </>

    )
}

export default Messages