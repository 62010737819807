import React, { useState, useEffect } from 'react'
import FramePeople from "../../Assets/Image/Frame.png"
import textName from "../../Assets/Image/text.png"
import textBlack from "../../Assets/Image/text black.png"
import tree from "../../Assets/Image/tree.png"
import Email from "../../Assets/svg/sms.svg"
import Back from "../../Assets/Image/Backs.png"
import { useNavigate } from 'react-router-dom'
import { forgotPassword } from '../../Services/ApiServices'
import toast from 'react-hot-toast'
import { ThreeCircles } from 'react-loader-spinner'

function ForgotPassword() {

    const navigate = useNavigate()
    const tokens = localStorage.getItem("UToken")

    const [email, setEmail] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (tokens) {
            navigate("/dashboard")
        }
    }, [])

    const handleSubmit = (e) => {
        setIsLoading(true)
        e.preventDefault()
        let param = {
            email_id: email
        }
        forgotPassword(param)
            .then((res) => {
                console.log("res >>", res);
                const status = res.data.Status;
                if (status == 1) {
                    console.log("forgot pass >>", res.data);
                    navigate("/verification", { state: { Email: email } })
                } else {
                    toast.error(res.data.Message)
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("err >>", err);
                setIsLoading(false)
            })
    }

    return (
        <div className="grid grid-cols-12 gap-x-6 bg-white">
            <div className='col-span-12 intro-y md:col-span-6 bg-black h-[100vh] md:flex items-end justify-center hidden'>
                <div>
                    <div className='flex justify-center pb-52'>
                        <img src={textName} className='w-60' alt="" />
                    </div>
                    <div className='flex h-[400px] md:h-[500px] 2xl:col-span-6 ms-10 relative'>
                        <img src={FramePeople} className='' alt="" />
                        <img src={tree} className='xl:block hidden' alt="" />
                    </div>
                </div>
            </div>
            <div className='col-span-12 intro-y md:col-span-6 '>
                <div>
                    <div className='py-6 px-6 md:px-0'>
                        <img src={Back} width={55} onClick={() => { navigate("/") }} alt="" />
                    </div>
                    <div className='text-center flex md:items-center justify-center items-top h-[80vh]'>
                        <form onSubmit={handleSubmit} className='md:mt-0 mt-10'>
                            <div className='flex justify-center mb-20 md:hidden block'>
                                <img src={textBlack} className='w-52' alt="" />
                            </div>
                            <p className='fontNew text-4xl font-bold p-3'>Forgot Password?</p>
                            <p className='fontNew text-base text-slate-500'>Enter your email address to get OTP?</p>
                            <div className='mt-10 mb-6'>
                                <div className='bg-zinc-100 flex p-2 rounded-2xl border-2 border-slate-300 my-3'>
                                    <img src={Email} className='p-2 w-11' alt="" />
                                    <input type="text" onChange={(e) => setEmail(e.target.value)} className='bg-transparent border-none outline-none w-full text-lg fontNew' placeholder='Email Address' />
                                </div>
                            </div>
                            <div>
                                <button type='submit' className='fontNew py-5 w-full rounded-2xl text-white font-bold text-base' style={{ backgroundColor: "#7424F2" }}>
                                    {
                                        isLoading ?
                                            <div className='flex justify-center'>
                                                <ThreeCircles
                                                    width={23}
                                                    height={23}
                                                    color="#fff"
                                                    ariaLabel="circles-loading"
                                                    wrapperStyle={{}}
                                                    visible={true}
                                                    wrapperClass=""
                                                />
                                            </div>
                                            :
                                            "Forgot Password"
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword