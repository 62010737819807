import { Toaster } from 'react-hot-toast';
import './App.css';
import RouterData from './RouterData/RouterData';
import 'react-medium-image-zoom/dist/styles.css'

function App() {
  return (
    <>
      <RouterData />
      <Toaster
        position="top-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: '',
          duration: 5000,
          style: {
            background: '#000',
            color: '#fff',
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: 'green',
              secondary: 'black',
            },
          },
        }}
      />
    </>
  );
}

export default App;
