import React, { useEffect, useState } from 'react'
import profile from '../../Assets/Image/noImage.png'
import axios from 'axios'
import { GET_EDIT_MY_PROFILE, IMG_URL } from '../../Services/Api'
import { now } from 'moment'
import { ThreeCircles } from 'react-loader-spinner'
import { editProfile } from '../../Services/ApiServices'
import Dialog from '../../Base-Component/Dialog/Dialog'
import xbtn from '../../Assets/svg/x button.svg'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

function Setting() {

  const navigate = useNavigate()
  const tokens = localStorage.getItem("UToken")

  const [image, setImage] = useState("")
  const [img, setImg] = useState("")
  const [serviceFee, setServiceFee] = useState("")
  const [formValue, setFormValue] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isLoader, setIsLoader] = useState(false)
  const [isModalOpens, setModalOpens] = useState(false);

  useEffect(() => {
    handleEditProfile()

    if (!tokens) {
      navigate("/login")
    }
  }, [])

  const handleEditProfile = () => {
    axios.post(GET_EDIT_MY_PROFILE)
      .then((res) => {
        const status = res.data.Status;
        const data = res.data.info;
        if (status == 1) {
          setFormValue(data)
          setImg(data.profile_pic ? IMG_URL + data.profile_pic : "")
          setServiceFee(data.service_fee_percent)
        } else {
          console.log("error >>", res.data.Message);
        }
      })
      .catch((err) => {
        console.log("err >>", err);
      })
  }

  const handleProfile = (e) => {
    setImage(e.target.files[0]);
    var createurlimage = URL.createObjectURL(e.target.files[0]);
    setImg(createurlimage);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const formData = new FormData()

    if (image) {
      formData.append("profile_pic", image)
    }
    if (formValue.first_name) {
      formData.append("first_name", formValue.first_name)
    }
    if (formValue.last_name) {
      formData.append("last_name", formValue.last_name)
    }
    if (serviceFee) {
      formData.append("service_fee_percent", serviceFee)
    }

    const first = formValue.first_name.replace(/[^<>?\/\\\-+=]/g, '');
    const last = formValue.last_name.replace(/[^<>?\/\\\-+=]/g, '');

    if (first || last) {
      toast.error("Please enter valid name")
      setTimeout(() => {
        setIsLoading(false)
        setIsLoader(false)
      }, 1000);
    } else {
      console.log("success");
      editProfile(formData)
        .then((res) => {
          const status = res.data.Status;
          const data = res.data.info
          if (status == 1) {
            console.log("res >>", res.data.Message);
            localStorage.setItem('Admin', JSON.stringify(data));
            handleEditProfile()
            setModalOpens(false)
            toast.success(res.data.Message)
          } else {
            console.log("error >>", res.data.Message);
            toast.error(res.data.Message)
          }
          setIsLoading(false)
          setIsLoader(false)
        })
        .catch((err) => {
          console.log("err >>", err);
          setIsLoading(false)
          setIsLoader(false)
        })
    }

  }

  return (
    <div className='grid grid-cols-12 gap-3'>

      <div className='col-span-12 lg:col-span-12 2xl:col-span-6 fontNew'>
        <div className='bg-white rounded-xl py-4 lg:px-7 px-3'>
          <p className='text-2xl font-semibold'>Account settings</p>
          <form onSubmit={(e) => { handleSubmit(e); setIsLoading(true) }}>
            <div className='flex items-center py-8'>
              <div className='pr-5'>
                <img src={img ? img : profile} className='w-18 h-18 rounded-full object-cover' alt="" />
              </div>
              <input type="file" id="chatroom-form" hidden multiple onChange={(e) => handleProfile(e)} accept='image/*' name="img" />
              <div className='upload-img-dp'>
                <label htmlFor="chatroom-form" className='text-[#7424F2] font-medium border-b-2 border-[#7424F2]'>Edit Photo</label>
              </div>
            </div>
            <div className='grid grid-cols-12'>

              <div className='lg:col-span-6 col-span-12 p-2'>
                <p className='font-medium text-gray-500 text-sm'>First Name</p>
                <div className='bg-zinc-100 flex px-5 py-4 rounded-2xl border-2 border-slate-300 my-3'>
                  <input
                    type="text"
                    className='bg-transparent border-none outline-none w-full text-base fontNew'
                    placeholder='enter your first name'
                    name='first_name'
                    max={20}
                    value={formValue.first_name || ""}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>

              <div className='lg:col-span-6 col-span-12 p-2'>
                <p className='font-medium text-gray-500 text-sm'>Last Name</p>
                <div className='bg-zinc-100 flex px-5 py-4 rounded-2xl border-2 border-slate-300 my-3'>
                  <input
                    type="text"
                    className='bg-transparent border-none outline-none w-full text-base fontNew'
                    placeholder='enter your last name'
                    name='last_name'
                    max={20}
                    value={formValue.last_name || ""}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>

              <div className='lg:col-span-6 col-span-12 p-2'>
                <p className='font-medium text-gray-500 text-sm'>Email Address</p>
                <div className='bg-zinc-100 flex px-5 py-4 rounded-2xl border-2 border-slate-300 my-3'>
                  <input
                    type="text"
                    className='bg-transparent border-none outline-none w-full text-base fontNew'
                    placeholder='enter your email address'
                    name='email_id'
                    value={formValue.email_id || ""}
                  />
                </div>
              </div>

            </div>

            <div className='pt-6 pb-2'>
              <button type='submit' className='bg-[#7424F2] rounded-xl lg:w-67 w-full text-xl py-4 px-4 text-white font-medium text-base lg:mx-3 mx-0'>
                {
                  isLoading ?
                    <div className='flex justify-center items-center'>
                      <ThreeCircles
                        width={27}
                        height={27}
                        color="#fff"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        visible={true}
                        wrapperClass=""
                      />
                    </div>
                    :
                    "Save Changes"
                }
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className='col-span-12 lg:col-span-12 2xl:col-span-6'>
        <div className='bg-white rounded-xl py-4 lg:px-7 px-3 h-[530px] fontNew'>
          <div className='flex justify-between'>
            <p className='text-2xl font-semibold'>Service Fee</p>
            <button onClick={() => setModalOpens(true)} className='bg-[#7424F2] rounded-xl text-lg py-2 px-4 text-white font-medium text-base lg:mx-3 mx-0'>Edit Fee</button>
          </div>

          <div className='py-9 bg-gray-200 my-4 w-60 text-center rounded-xl'>
            <p className='text-4xl font-semibold'>{formValue.service_fee_percent || "0"}%</p>
          </div>
        </div>
      </div>

      <Dialog
        open={isModalOpens}
        onClose={() => setModalOpens(false)}
        size="md"
      >
        <Dialog.Panel>
          <Dialog.Description className="grid grid-cols-12">
            <div className='col-span-12 sm:col-span-12 text-center fontNew px-3 pb-3'>
              <div onClick={() => { setModalOpens(false) }} className='flex justify-end mt-2 cursor-pointer'>
                <img src={xbtn} className='w-8' alt="" />
              </div>
              <form onSubmit={(e) => { handleSubmit(e); setIsLoader(true) }}>
                <input type="number" value={serviceFee} max={100} min={0} onChange={(e) => setServiceFee(e.target.value)} className='bg-zinc-100 flex w-full p-2 mt-6 rounded-2xl border-2 border-slate-300 outline-none text-lg fontNew' placeholder='enter service fee' />

                <button type='submit' className='fontNew py-3 px-6 mt-10 w-40 rounded-2xl text-white font-medium text-base bg-[#7424F2]'>
                  {
                    isLoader ?
                      <div className='flex justify-center items-center'>
                        <ThreeCircles
                          width={24}
                          height={24}
                          color="#fff"
                          ariaLabel="circles-loading"
                          wrapperStyle={{}}
                          visible={true}
                          wrapperClass=""
                        />
                      </div>
                      :
                      "Save Changes"
                  }
                </button>
              </form>
            </div>
          </Dialog.Description>
        </Dialog.Panel>
      </Dialog>
    </div>
  )
}

export default Setting