
export const BASE_URL = "https://app.xtrakare.com:8000";
export const IMG_URL = "https://app.xtrakare.com/xtrakare/"

export const ADMIN_LOGIN_API = BASE_URL + "/admin_login"
export const FORGOT_PASSWORD_API = BASE_URL + "/forgot_password"
export const VERIFICATION_EMAIL_API = BASE_URL + "/verification_for_email"
export const RESET_PASSWORD_API = BASE_URL + "/reset_password"

export const DASHBOARD_COUNT_API = BASE_URL + "/dashboard_count"
export const GET_SHIFT_LIST_API = BASE_URL + "/list_shift_from_admin"
export const GET_CLOCK_LIST_API = BASE_URL + "/list_clockin_and_clockout"

export const LIST_ADMIN_PAYMENT_API = BASE_URL + "/list_admin_payments"
export const PAYMENT_BY_ADMIN = BASE_URL + "/capture_payment_by_admin"
export const PAYMENT_BY_MANUALLY_ADMIN = BASE_URL + "/admin_send_payment_manually"
export const SEND_NOTIFICATION_API = BASE_URL + "/send_reminder_notification"

export const USERS_LIST_API = BASE_URL + "/list_user"
export const SEARCH_USERS_LIST_API = BASE_URL + "/search_user"
export const DELETE_ACCOUNT = BASE_URL + "/delete_account"

export const PROFESSIONAL_LIST_API = BASE_URL + "/list_professional"
export const SEARCH_PROFESSIONAL_LIST_API = BASE_URL + "/search_professional"
export const CHANGE_PRO_STATUS_API = BASE_URL + "/change_profile_status"
export const SKILL_ACTIVATE_API = BASE_URL + "/skill_activate"

export const GET_EDIT_MY_PROFILE = BASE_URL + "/get_and_edit_my_profie"

export const CLOCK_IN_SHIFT = BASE_URL + "/clock_in_shift_by_admin"
export const CLOCK_OUT_SHIFT = BASE_URL + "/clock_out_shift_by_admin"

export const DELETE_ACCOUNT_API = BASE_URL + "/delete_account_web"

export const LIST_SERVICE_API = BASE_URL + "/list_service"
export const LIST_ADDRESS_API = BASE_URL + "/list_address"

export const ADD_ADDRESS_API = BASE_URL + "/add_address"
export const EDIT_ADDRESS_API = BASE_URL + "/edit_address"

export const LIST_SKILL_PROFESSIONAL = BASE_URL + "/list_skillwise_professional"
export const SEARCH_SKILL_PROFESSIONAL = BASE_URL + "/search_skillwise_professional"

export const ADD_POST_ADMIN = BASE_URL + "/add_post_by_admin"

export const LIST_NOTIFICATION_API = BASE_URL + "/list_notification_by_admin"

export const EARNING_DASHBOARD_API = BASE_URL + "/earning_dashboard"
export const GRAPH_DATA_API = BASE_URL + "/graph_data"
