import React, { useState, useEffect } from 'react'
import leftbtn from '../../Assets/Image/left btn.png'
import rightbtn from '../../Assets/Image/right btn.png'
import FormSelect from '../../Base-Component/FormSelect/FormSelect'
import { clockInShift, getShiftList } from '../../Services/ApiServices'
import { Bars, ThreeCircles } from 'react-loader-spinner'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import xbtn from '../../Assets/svg/x button.svg'
import Dialog from '../../Base-Component/Dialog/Dialog'
import toast from 'react-hot-toast'

function Shifts() {


  const navigate = useNavigate()
  const tokens = localStorage.getItem("UToken")

  const [isLoader, setIsLoader] = useState(false)
  const [isLoad, setIsLoad] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false);

  const [pageNo, setPageNo] = useState(1)
  const [selectShift, setSelectShift] = useState(0)
  const [totalPage, setTotalPage] = useState()

  const [serviceId, setServiceId] = useState("")

  const [userData, setUserData] = useState([])

  useEffect(() => {
    if (!tokens) {
      navigate("/login")
    }
  }, [])

  useEffect(() => {
    handleShift()
  }, [pageNo])

  useEffect(() => {
    setPageNo(1)
    handleShift()
  }, [selectShift])

  const handleShift = () => {
    setIsLoader(true)
    let param = {
      page_no: pageNo,
      shift_status: selectShift
    }
    getShiftList(param)
      .then((res) => {
        const status = res.data.Status;
        const data = res.data.info;
        if (status == 1) {
          setUserData(data)
          setTotalPage(res.data.total_page)
        } else {
          console.log("error >>", res.data.Message);
        }
        setIsLoader(false)
      })
      .catch((err) => {
        console.log("err >>", err);
        setIsLoader(false)
      })
  }

  const renderPaginationButtonss = () => {
    const maxPagesToShow = 3;
    const buttons = [];
    const startPage = Math.max(1, pageNo - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPage, startPage + maxPagesToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={i === pageNo ? 'px-3 py-1 mx-1 bg-black rounded-full text-white' : 'px-3 py-1 mx-1'}
        >
          {i}
        </button>
      );
    }
    return buttons;
  };

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsLoad(true)
    let param = { post_service_id: serviceId }
    clockInShift(param)
      .then((res) => {
        const status = res.data.Status;
        if (status == 1) {
          setModalOpen(false)
          handleShift()
        } else {
          toast.error(res.data.Message)
        }
        setIsLoad(false)
      })
      .catch((err) => {
        console.log("err>>", err);
      })
  }

  return (
    <div className='grid grid-cols-12 fontNew bg-white rounded-2xl'>
      <div className='col-span-12 font-semibold fontNew lg:mx-10 mx-6 my-6 flex justify-between items-center'>
        <p className='text-2xl'>Shifts</p>
        <div>
          <FormSelect id="category" name="fuel_type" value={selectShift} onChange={(e) => setSelectShift(e.target.value)} className="select-arrow-hidden text-base font-medium lg:w-60 w-40 py-3 rounded-lg outline-0 bg-[#F7F7F7]">
            <option value={0}>All</option>
            <option value={1}>Approved</option>
            <option value={2}>Clock in</option>
            <option value={3}>Clock Out</option>
            <option value={4}>Completed</option>
            <option value={5}>Cancelled</option>
          </FormSelect>
        </div>
      </div>

      <div className='col-span-12 overflow-auto border-[1px]'>
        {
          isLoader ?
            <div className='h-[585px] w-full flex items-center justify-center'>
              <Bars
                width={30}
                height={30}
                color="#000"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                visible={true}
                wrapperClass=""
              />
            </div>
            :
            <>
              {
                userData == "" ?
                  <div className='h-[75vh] w-full flex items-center justify-center'>
                    <p className='text-center text-lg font-semibold text-gray-500'>Shift Data Not Found</p>
                  </div>
                  :
                  <table className='w-full'>
                    <tr className='bg-gray-100 text-gray-500'>
                      <th className='py-4 px-5 text-base font-semibold whitespace-nowrap text-start'>#</th>
                      <th className='py-4 px-3 text-base font-semibold whitespace-nowrap text-start'>User Name</th>
                      <th className='py-4 px-3 text-base font-semibold whitespace-nowrap text-start'>Role</th>
                      <th className='py-4 px-3 text-base font-semibold whitespace-nowrap text-start'>Date</th>
                      <th className='py-4 px-3 text-base font-semibold whitespace-nowrap text-start'>Professional Name</th>
                      <th className='py-4 px-3 text-base font-semibold whitespace-nowrap text-start'>Start Time</th>
                      <th className='py-4 px-3 text-base font-semibold whitespace-nowrap text-start'>End Time</th>
                      <th className='py-4 px-3 text-base font-semibold whitespace-nowrap text-start'>Working Hours</th>
                      <th className='py-4 px-3 text-base font-semibold whitespace-nowrap text-start'>Clock In</th>
                      <th className='py-4 px-3 text-base font-semibold whitespace-nowrap text-start'>Pay Rate</th>
                      <th className='py-4 px-3 text-base font-semibold whitespace-nowrap text-start'>Earnings</th>
                      <th className='py-4 px-3 text-base font-semibold whitespace-nowrap text-start'>Status</th>
                    </tr>
                    {
                      userData.map((item, index) => {
                        const postStartTime = new Date(item.post_start_time);
                        const postEndTime = new Date(item.post_end_time);
                        const differenceInMilliseconds = postEndTime.getTime() - postStartTime.getTime();
                        const differenceInHours = (differenceInMilliseconds / (1000 * 3600)).toFixed(2);
                        const differenceInMinutes = (differenceInMilliseconds / (1000 * 60)).toFixed(2);
                        const hours = differenceInHours;
                        const minutes = differenceInMinutes;
                        return (
                          <tr className='bg-white text-sm font-semibold border-b border-zinc-100' key={index}>
                            <td className='text-start p-4'>
                              <div className='whitespace-nowrap'>
                                {item.post_service_id || "-"}
                              </div>
                            </td>
                            <td className='text-start p-4'>
                              <div className='whitespace-nowrap'>
                                {item.user_first_name || "-"} {item.user_last_name || "-"}
                              </div>
                            </td>
                            <td className='text-start p-4'>
                              <div className='whitespace-nowrap'>
                                {item.service_name || "-"}
                              </div>
                            </td>
                            <td className='text-start p-4'>
                              <div className='whitespace-nowrap'>
                                {moment(item.service_date).format("DD/MM/YYYY") || "-"}
                              </div>
                            </td>
                            <td className='text-start p-4'>
                              <div className='w-32 overflow-hidden whitespace-nowrap text-ellipsis'>{item.professional_first_name || "-"} {item.professional_last_name || "-"}</div>
                            </td>
                            <td className='text-start p-4'>
                              <div className='whitespace-nowrap'>
                                {/* {item.clock_in == null ?
                                  moment(item.post_start_time).format("LT")
                                  :
                                  moment(item.clock_in).format("LT")
                                } */}
                                {moment(item.post_start_time).local().format("LT")}
                              </div>
                            </td>
                            <td className='text-start p-4'>
                              <div className='whitespace-nowrap'>
                                {/* {item.clock_out == null ? moment(item.post_end_time).format("LT") : moment(item.clock_out).format("LT")} */}
                                {moment(item.post_end_time).local().format("LT")}
                              </div>
                            </td>
                            <td className='text-start p-4'>
                              <div className='w-32 overflow-hidden whitespace-nowrap text-ellipsis'>
                                {
                                  item.shift_length == 0 ?
                                    <>{hours}/hr</>
                                    :
                                    <>{item.shift_length || "0"} /hr</>
                                }
                              </div>
                            </td>
                            <td className='text-start p-4'>
                              <div className='whitespace-nowrap'>
                                {
                                  item.post_services_status == 1 && item.clock_in == null ?
                                    <button onClick={() => { setServiceId(item.post_service_id); setModalOpen(true) }} className='bg-[#7424f21a] py-2 px-3 text-[#7424F2] rounded-lg border-[1px] border-[#7424F2]'>Clock in</button>
                                    : "-"
                                }
                              </div>
                            </td>
                            <td className='text-start p-4'>
                              <div className='whitespace-nowrap'>
                                ${item.service_hourly_price || "0"} /hr
                              </div>
                            </td>
                            <td className='text-start p-4 text-green-600'>
                              <div className='whitespace-nowrap'>
                                ${item.sub_amount || "0"}
                              </div>
                            </td>
                            <td className='text-start py-4 px-3 text-sm truncate font-semibold'>
                              <div>
                                {
                                  item.post_services_status == 1 && item.clock_in == null ?
                                    <button className='bg-[#f2994a1a] py-2 px-3 text-[#F2994A] rounded-lg'>Approved</button>
                                    : item.post_services_status == 1 && item.clock_in != null ?
                                      <button className='bg-[#7424f21a] py-2 px-3 text-[#7424F2] rounded-lg'>Clock in</button>
                                      : item.post_services_status == 2 && item.clock_out != null ?
                                        <button className='bg-[#2336E41A] py-2 px-3 text-[#2336E4] rounded-lg'>Clock Out</button>
                                        : item.post_services_status == 3 || item.post_services_status == 7 ?
                                          <button className='bg-[#038a001a] py-2 px-3 text-[#038A00] rounded-lg'>Completed</button>
                                          : <button className='bg-[#e423231a] py-2 px-3 text-[#E42323] rounded-lg'>Cancelled</button>
                                }
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </table>
              }
            </>
        }
      </div>

      {
        userData == "" ? ""
          :
          <div className='col-span-12 flex items-center justify-end lg:mx-10 px-3 py-4'>
            <div className='flex items-center cursor-pointer'>
              <button onClick={() => handlePageChange(pageNo - 1)} disabled={pageNo === 1}>
                <img src={leftbtn} className='w-9 mx-3' alt="" />
              </button>
              <div className="flex items-center">
                {renderPaginationButtonss()}
              </div>
              <button onClick={() => handlePageChange(pageNo + 1)} disabled={pageNo === totalPage}>
                <img src={rightbtn} className='w-9 mx-3' alt="" />
              </button>
            </div>
          </div>
      }

      <Dialog
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        size="md"
      >
        <Dialog.Panel>
          <Dialog.Description className="grid grid-cols-12">
            <div className='col-span-12 sm:col-span-12 text-center fontNew px-3 pb-3'>
              <div onClick={() => { setModalOpen(false) }} className='flex justify-end mt-2 cursor-pointer'>
                <img src={xbtn} className='w-8' alt="" />
              </div>
              <div className='my-4'>
                <p className='text-3xl font-bold pb-1'>Shift</p>
                <p className='text-lg font-medium text-gray-500'>Are you sure you want to Clock In ?</p>
              </div>

              <button onClick={(e) => handleSubmit(e)} className='fontNew py-3 px-6 mt-6 w-40 rounded-2xl text-white font-medium text-base bg-[#7424F2]'>
                {
                  isLoad ?
                    <div className='flex justify-center items-center'>
                      <ThreeCircles
                        width={24}
                        height={24}
                        color="#fff"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        visible={true}
                        wrapperClass=""
                      />
                    </div>
                    :
                    "Yes"
                }
              </button>
            </div>
          </Dialog.Description>
        </Dialog.Panel>
      </Dialog>
    </div >
  )
}

export default Shifts


